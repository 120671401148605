import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "./components/Navbar";
import Home from "./pages";
import Bloom from "./pages/bloom";
import Finance from "./pages/finance";
import Meru from "./pages/meru";
import Resume from "./pages/resume";

function App() {
  /*Adds title name to all components and pages.*/
  useEffect(() => {
    document.title = "Pepe Tapia";
  }, []);
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/bloom" element={<Bloom />} />
        <Route exact path="/finance-buddy" element={<Finance />} />
        <Route exact path="/meru" element={<Meru />} />
        <Route exact path="/resume" element={<Resume />} />
        {/* <Route exact path="/sports-recording" element={<Epal />} /> */}
        {/* <Route exact path="/wild-ai" element={<WildAi />} /> */}
        {/* <Route exact path="/aws-ai-ml-scholarship" element={<AwsAiMl />} /> */}
        {/* <Route exact path="/loudgg-val" element={<LoudVal />} /> */}
        {/* <Route path="/notfound-gg" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
