import React, { useEffect, useRef, useState } from "react";
//import logo from './logo.png';
import AudienceBar from "../components/AudienceBar";
import ProjectHeadline from "../components/Headline/ProjectHeadline";
import BloomBack from "../components/bloom/bloomback";
import BloomData from "../components/bloom/bloomdata";
import BloomFront from "../components/bloom/bloomfront";
import BloomMore from "../components/bloom/bloommore";
import "./index.css";
const sections = [
  {
    id: "situation",
    title: "Uniting different data sources to improve productivity",
    content:
      "Enterprise data is often siloed and difficult to access. By creating a chat interface that can consolidate historic and real-time data into one interface, we can help users discover insights and make better decisions.",
  },
  {
    id: "task",
    title: "Creating a full stack application",
    content:
      "The goal was to create an application that can consolidate historic and real-time data into one interface. By utilizing modern NLP technologies, we can help users discover insights and make better decisions.",
  },
  {
    id: "action",
    title: "Design, Develop, Deliver",
    content:
      "I took the initial design and narrative documents and decided to focus heavily on the backend infrastructure and AI implementation. By utilizing a data centric approach, I was able to integrate multiple data sources such as Microsoft 365, Quickbooks, Phonecall transcripts, and a ticket system to deliver a working beta.",
  },
  {
    id: "result",
    title: "Results",
    content:
      "After a successful beta, the user can now increase their productivity where it matters. Writing executive reports down from 3 days to 10 minutes. Meeting times down from 45 minutes to 5 minutes. Response times from emails and phone calls down from 1-3 days to 4 hours or less. ",
  },
  {
    id: "role",
    title: "Applied AI Full Stack Developer",
    content:
      "As an Applied AI Full Stack Developer, I was responsible for the entire software development lifecycle. From initial design to final deployment, I was able to deliver a working beta in 2 months. By utilizing modern NLP technologies, I was able to help users discover insights and make better decisions.",
  },
];

const Bloom = () => {
  const [currentDev, setCurrentDev] = useState("frontend");
  const overflowContainerRef = useRef(null);

  useEffect(() => {
    if (overflowContainerRef.current) {
      overflowContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentDev]);
  const imgComponent = (
    <div className="w-full">
      <img
        className="rounded-lg shadow-lg shadow-c-white"
        alt=""
        src="/bloom/bloom_0524.png"
      ></img>
    </div>
  );

  return (
    <div className="mx-auto h-full w-5/6 px-4 max-md:w-full">
      <ProjectHeadline
        mainText={"Bloom Chat"}
        subText={
          "Generative AI on Enterprise Data. Increase productivity where it matters."
        }
        imgComponent={imgComponent}
        sections={sections}
      />
      <section className="m-auto mt-12 p-2 max-md:mt-4 max-md:p-0">
        <AudienceBar currentDev={currentDev} setCurrentDev={setCurrentDev} />
      </section>
      <section
        id="info"
        ref={overflowContainerRef}
        className="h-3/4 overflow-y-auto overscroll-auto p-2"
      >
        {currentDev === "frontend" && <BloomFront />}
        {currentDev === "backend" && <BloomBack />}
        {currentDev === "datascience" && <BloomData />}
        {currentDev === "more" && <BloomMore />}
      </section>
    </div>
  );
};

export default Bloom;
