import { React, useState } from "react";
import EventCard from "../EventCard";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import {
  MeruBackOverall1,
  MeruBackOverall2,
  MeruBackTLDR,
  MeruBackTools,
} from "./content";

const MeruBack = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div id="events" className="flex w-full flex-col">
      <Tooling tools={MeruBackTools} />
      <TLDRButton
        onClick={handleOpenModal}
        buttonText="Backend in a nutshell"
      />
      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={MeruBackTLDR}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <EventCard
          title={"Ingestion using Riot Games API"}
          subtitle={"Respect the rate limits!"}
          overallContent={MeruBackOverall1}
          // techContent={MeruBackTech1}
          // thoughtsContent={MeruBackThoughts1}
          // mixContent={MeruBackMix1}
        />

        <EventCard
          title={"Deliver a unique experience with AI"}
          subtitle={"Customize your data"}
          overallContent={MeruBackOverall2}
          // techContent={MeruBackTech2}
          // thoughtsContent={MeruBackThoughts2}
          // mixContent={MeruBackMix2}
        />
      </div>
    </div>
  );
};

export default MeruBack;
