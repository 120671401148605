import { React, useState } from "react";
import EventCard from "../EventCard";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import { MeruAIMLOverall1, MeruAIMLTLDR, MeruAIMLTools } from "./content";
const MeruData = () => {
  const tools = [
    { name: "Python", link: "https://www.python.org/" },
    { name: "sklearn", link: "https://scikit-learn.org/" },
    { name: "Neo4j Data Science", link: "https://neo4j.com/" },
    { name: "pytorch", link: "https://pytorch.org/" },
    { name: "tensorflow", link: "https://www.tensorflow.org/" },
    { name: "Jupyter Notebook", link: "https://jupyter.org/" },
  ];
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const tldrContent = (
    <div className="text text-c-black">
      <h2 className="mb-2 text-center text-2xl font-bold">
        AI / Data Science - FAQs and Important Notes.
      </h2>
      <p className="mb-4 text-center text-xs font-bold">
        Things I learned or things I think are important to highlight.
      </p>
      <p className="text-center text-lg font-bold">In Progress...</p>
    </div>
  );

  return (
    <div id="events" className="flex w-full flex-col">
      <Tooling tools={MeruAIMLTools} />

      <TLDRButton
        onClick={handleOpenModal}
        buttonText="AI/ML & Data Science in a nutshell"
      />

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={MeruAIMLTLDR}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <EventCard
          title="XGBoost is all you need."
          subtitle="Well, kind of."
          overallContent={MeruAIMLOverall1}
        />
      </div>
    </div>
  );
};

export default MeruData;
