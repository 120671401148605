import React from "react";
import ProjectTile from "./ProjectTile";

const PreviewPanel = ({ projectList }) => {
  return (
    <section
      id="projects"
      className="bg-gradient-to-b from-c-black/20 via-c-black/10 to-c-black/5 py-16"
    >
      <div className="container mx-auto px-4">
        <h2 className="mb-12 text-center text-4xl font-bold text-white">
          Explore My{" "}
          <span className="bg-gradient-to-r from-c-blue-mono to-c-pink-mono bg-clip-text text-transparent">
            Applications
          </span>
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {projectList.map((project, index) => (
            <ProjectTile
              key={index}
              image={project.image}
              title={project.title}
              description={project.description}
              liveLink={project.liveLink}
              moreLink={project.moreLink}
              github={project.github}
              projectStatus={project.projectStatus}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PreviewPanel;
