import React, { useEffect, useRef, useState } from "react";
import "./index.css"; // We'll create this file for the CSS

const FutureContent = () => (
  <div className="flex h-full w-full items-center justify-center">
    <p className="text-center text-lg text-gray-400">
      I'm currently working on this section. <br />
      It will be updated once completed.
    </p>
  </div>
);

const EventCard = ({
  title,
  subtitle,
  overallContent,
  techContent,
  thoughtsContent,
  mixContent,
}) => {
  const [activeButton, setActiveButton] = useState("overall");
  const [currentDisplay, setCurrentDisplay] = useState({
    display: overallContent,
    isFuture: false,
  });
  const [showGlow, setShowGlow] = useState(false);
  const scrollContainerRef = useRef(null);

  const getContent = () => {
    switch (activeButton) {
      case "overall":
        return { display: overallContent, isFuture: false };
      case "tech":
        return {
          display: techContent || <FutureContent />,
          isFuture: !techContent,
        };
      case "thoughts":
        return {
          display: thoughtsContent || <FutureContent />,
          isFuture: !thoughtsContent,
        };
      case "mix":
        return {
          display: mixContent || <FutureContent />,
          isFuture: !mixContent,
        };
      default:
        return { display: overallContent, isFuture: false };
    }
  };

  useEffect(() => {
    setCurrentDisplay(getContent());
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
    setShowGlow(false);
    setTimeout(() => setShowGlow(true), 50);
  }, [activeButton]);

  const getButtonClasses = (button) => {
    const baseClasses =
      "px-4 py-2 rounded-md text-sm font-medium transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500";
    const activeClasses =
      "bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-md";
    const inactiveClasses = "bg-gray-800 text-gray-300 hover:bg-gray-700";

    return `${baseClasses} ${activeButton === button ? activeClasses : inactiveClasses}`;
  };

  const getGlowColor = () => {
    switch (activeButton) {
      case "tech":
        return "from-blue-500";
      case "thoughts":
        return "from-pink-500";
      case "mix":
        return "from-purple-500";
      default:
        return "from-white";
    }
  };

  return (
    <div className="relative overflow-hidden rounded-lg bg-gray-900 shadow-xl">
      <div className="p-6">
        <h2 className="mb-2 text-2xl font-bold text-white">{title}</h2>
        <p className="mb-6 text-gray-400">{subtitle}</p>

        <div className="mb-6 flex flex-wrap gap-2">
          {["overall", "tech", "thoughts", "mix"].map((button) => (
            <button
              key={button}
              className={getButtonClasses(button)}
              onClick={() => setActiveButton(button)}
            >
              {button.charAt(0).toUpperCase() + button.slice(1)}
            </button>
          ))}
        </div>

        <div className="relative min-h-[400px] overflow-hidden rounded-lg bg-gray-800 p-6">
          {showGlow && (
            <div
              className={`glow-effect bg-gradient-to-r ${getGlowColor()} to-transparent`}
            />
          )}
          <div
            ref={scrollContainerRef}
            className="relative z-10 h-full overflow-y-auto"
          >
            {currentDisplay.isFuture ? (
              <FutureContent />
            ) : (
              <div className="flex flex-col gap-6 lg:flex-row">
                <div className="lg:w-1/2">
                  <div className="prose prose-invert max-w-none">
                    {currentDisplay.display.display}
                  </div>
                </div>
                <div className="flex items-center justify-center lg:w-1/2">
                  <div className="max-h-full max-w-full overflow-hidden">
                    {currentDisplay.display.images}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
