import { React, useState } from "react";
import EventCard from "../EventCard";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import {
  BloomBackOverall1,
  BloomBackOverall2,
  BloomBackOverall3,
  BloomBackOverall4,
  BloomBackTLDR,
  BloomBackTools,
} from "./content";

const BloomBack = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div id="events" className="flex w-full flex-col">
      <Tooling tools={BloomBackTools} />

      <TLDRButton
        onClick={handleOpenModal}
        buttonText="Backend in a nutshell"
      />

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={BloomBackTLDR}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <EventCard
          title="Data centric applications"
          subtitle="Full ownership of the data, infrastructure, and ingestion process."
          overallContent={BloomBackOverall1}
        />

        <EventCard
          title="The Architecture"
          subtitle="When does a service become a microservice?"
          overallContent={BloomBackOverall2}
        />

        <EventCard
          title="The AI Engine"
          subtitle="It's just an AI model, chill."
          overallContent={BloomBackOverall3}
        />

        <EventCard
          title="User authorization and data privacy"
          subtitle="Imagine if a user saw the personal emails of the CEO? YIKES."
          overallContent={BloomBackOverall4}
        />
      </div>
    </div>
  );
};

export default BloomBack;
