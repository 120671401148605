import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./index.css";

const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="m-auto flex w-5/6 flex-col items-center py-6 font-sans max-md:w-full">
      {" "}
      {/* Changed to flex-col and added items-center */}
      <div className="mb-4 flex flex-row items-center px-4">
        {" "}
        {/* Added mb-4 */}
        <Link
          className="flex justify-center text-2xl font-bold uppercase leading-none no-underline"
          to="/"
        >
          <h1 className="text-2xl">
            Jose Antonio <span className="text-c-pink-mono">Pepe</span> Tapia
          </h1>
        </Link>
      </div>
      <div className="flex items-center justify-start font-bold no-underline">
        <div className="relative inline-block" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="flex items-center text-lg font-bold no-underline hover:text-c-blue-mono max-md:text-sm"
          >
            Projects
            <svg
              className="ml-1 h-4 w-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 z-1 mt-2 w-48 rounded-md bg-gray-800 shadow-lg">
              <ul className="py-2">
                <li>
                  <NavLink
                    to="/bloom"
                    onClick={closeDropdown} // Close on link click
                    className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                  >
                    Bloom
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/finance-buddy"
                    onClick={closeDropdown} // Close on link click
                    className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                  >
                    Finance Buddy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/meru"
                    onClick={closeDropdown} // Close on link click
                    className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                  >
                    Meru
                  </NavLink>
                </li>
                {/* Add more project links here */}
              </ul>
            </div>
          )}
        </div>
        <NavLink
          to="/resume"
          className="ml-8 flex items-center text-lg font-bold no-underline hover:text-c-blue-mono max-md:text-sm"
        >
          Resume
        </NavLink>
      </div>
    </div>
  );
};

export default NavBar;
