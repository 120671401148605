const AudienceBar = ({ currentDev, setCurrentDev }) => {
  const handleClick = (dev) => {
    setCurrentDev(dev);
    window.scrollTo({ top: window.scrollY + 100, behavior: "smooth" });
  };

  const getButtonClasses = (dev) => {
    return `px-4 py-2 rounded-lg font-bold transition-colors duration-300 ${
      currentDev === dev
        ? "bg-gradient-to-r from-c-blue-mono to-c-pink-mono text-white"
        : "bg-gray-800 text-gray-300 hover:bg-gray-700"
    }`;
  };

  return (
    <div className="flex flex-wrap justify-center gap-4">
      <button
        className={getButtonClasses("frontend")}
        onClick={() => handleClick("frontend")}
      >
        Frontend
      </button>
      <button
        className={getButtonClasses("backend")}
        onClick={() => handleClick("backend")}
      >
        Backend
      </button>
      <button
        className={getButtonClasses("datascience")}
        onClick={() => handleClick("datascience")}
      >
        AI/ML & Data Science
      </button>
      <button
        className={getButtonClasses("more")}
        onClick={() => handleClick("more")}
      >
        More
      </button>
    </div>
  );
};

export default AudienceBar;
