import { React, useState } from "react";
import EventCard from "../EventCard";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import {
  BloomFrontOverall1,
  BloomFrontOverall2,
  BloomFrontOverall3,
  BloomFrontOverall4,
  BloomFrontTLDR,
  BloomFrontTools,
} from "./content";
const BloomFront = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div id="events" className="flex w-full flex-col">
      <Tooling tools={BloomFrontTools} />

      <TLDRButton
        onClick={handleOpenModal}
        buttonText="Frontend in a nutshell"
      />

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={BloomFrontTLDR}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <EventCard
          title="Iterate. Fail. Learn. Repeat."
          subtitle="I'm not a UI/UX designer."
          overallContent={BloomFrontOverall1}
        />
        <EventCard
          title="Create React App in 2023?"
          subtitle="Learning best practice and modern tooling."
          overallContent={BloomFrontOverall2}
        />

        <EventCard
          title="Do not reinvent the wheel."
          subtitle="Simplicity is key. But so is understanding the fundamentals."
          overallContent={BloomFrontOverall3}
        />

        <EventCard
          title="Get the job done."
          subtitle="A new UI/UX designer has entered the chat."
          overallContent={BloomFrontOverall4}
        />
      </div>
    </div>
  );
};

export default BloomFront;
