import React from "react";

const Resume = () => {
  return (
    <div className="m-auto h-4/5 w-5/6">
      <embed src="josetapia_resume.pdf" className="m-auto h-full md:w-3/4" />
    </div>
  );
};
export default Resume;
