import { React, useState } from "react";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import { MeruMoreTLDR, MeruMoreTools } from "./content";
const MeruMore = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div id="events" className="flex w-full flex-col">
      <Tooling tools={MeruMoreTools} />

      <TLDRButton onClick={handleOpenModal} buttonText="More in a nutshell" />

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={MeruMoreTLDR}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <div id="eventcard" className="flex justify-center">
          <div className="flex w-full flex-col">
            <h2 className="text-4xl font-bold">Write ups in progress..</h2>
          </div>
        </div>
      </div>

      {/* <div
        id="eventcards"
        className="m-auto mt-8 flex min-w-full flex-col gap-2"
      >
        <EventCard
          title="More"
          subtitle="In Progress..."
          overallContent={MeruMoreOverall1}
        />
      </div> */}
    </div>
  );
};

export default MeruMore;
