import React from "react";

const Headline = () => {
  return (
    <section className="relative h-[75%] overflow-hidden text-white">
      <div className="absolute inset-0 overflow-hidden">
        <img
          src="future_self.png"
          alt="Pepe Tapia coding"
          className="h-full w-full object-cover object-center opacity-20"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-c-black/20 via-c-black/10 to-c-black/5"></div>
      </div>

      <div className="relative flex h-full flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
        <h1 className="mb-4 text-center text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
          Let me <span className="text-c-blue-mono">build</span> something for{" "}
          <span className="text-c-pink-mono">you</span>.
        </h1>

        <p className="mb-8 max-w-3xl text-center font-semibold text-gray-300 sm:text-2xl">
          Crafting <span className="text-c-blue-mono">Bespoke Solutions</span>{" "}
          for <span className="text-c-pink-mono">Your Unique Needs</span>.
        </p>

        <p className="mb-12 max-w-2xl text-center text-xs text-gray-400 sm:text-lg">
          Hi, I'm Jose Antonio 'Pepe' Tapia, an Applied AI/ML Full Stack
          Developer based in Southern California. Let's turn your ideas into
          reality with practical solutions and a dedication to innovation.
        </p>

        <div className="inline-flex items-center gap-4 rounded-sm border-2 border-c-blue-mono px-4 py-2 backdrop-blur-sm">
          <p className="text-sm font-bold text-c-blue-mono md:text-base">
            Contact me:
          </p>
          <a
            href="https://github.com/PepeTapia"
            target="_blank"
            rel="noreferrer"
            className="transition-opacity hover:opacity-80"
          >
            <img src="github-mark-white.svg" alt="Github" className="h-8 w-8" />
          </a>
          <a
            href="https://www.linkedin.com/in/jose-pepe-tapia"
            target="_blank"
            rel="noreferrer"
            className="transition-opacity hover:opacity-80"
          >
            <img src="linkedin-mark.png" alt="LinkedIn" className="h-8 w-8" />
          </a>
          <a
            href="https://twitter.com/pepetapia"
            target="_blank"
            rel="noreferrer"
            className="transition-opacity hover:opacity-80"
          >
            <img src="x_icon.png" alt="Twitter" className="h-8 w-8" />
          </a>
        </div>

        <a
          href="https://www.youtube.com/watch?v=nhda21qxl3A"
          target="_blank"
          rel="noreferrer"
          className="group relative mt-4 inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-c-blue-mono to-c-pink-mono p-0.5 text-sm font-medium text-gray-900 hover:text-white focus:outline-none focus:ring-4 focus:ring-c-blue-mono"
        >
          <span className="relative rounded-md bg-white px-5 py-2.5 transition-all duration-75 ease-in group-hover:bg-opacity-0 dark:bg-gray-900">
            <span className="flex items-center">
              <svg
                className="mr-2 h-4 w-4 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
              </svg>
              Watch my AWS AI/ML Feature
            </span>
          </span>
        </a>
      </div>
    </section>
  );
};

export default Headline;
