import { React, useState } from "react";
import EventCard from "../EventCard";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import {
  MeruFrontMix1,
  MeruFrontMix2,
  MeruFrontOverall1,
  MeruFrontOverall2,
  MeruFrontTLDR,
  MeruFrontTech1,
  MeruFrontTech2,
  MeruFrontThoughts1,
  MeruFrontThoughts2,
  MeruFrontTools,
} from "./content";
const MeruFront = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div id="events" className="flex w-full flex-col">
      <Tooling tools={MeruFrontTools} />
      <TLDRButton
        onClick={handleOpenModal}
        buttonText="Frontend in a nutshell"
      />

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={MeruFrontTLDR}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <EventCard
          title="Intro to Meru"
          subtitle="Holistic Design"
          overallContent={MeruFrontOverall1}
          techContent={MeruFrontTech1}
          thoughtsContent={MeruFrontThoughts1}
          mixContent={MeruFrontMix1}
        />

        <EventCard
          title="Fundamentals of Frontend"
          subtitle="Uniformity, good practice, and more."
          overallContent={MeruFrontOverall2}
          techContent={MeruFrontTech2}
          thoughtsContent={MeruFrontThoughts2}
          mixContent={MeruFrontMix2}
        />
      </div>
    </div>
  );
};

export default MeruFront;
