import React from "react";
import {
  blueLightbulbIndicator,
  greenCheckmark,
  redXindicator,
} from "../../assets/svg";
import SmoothenlargeableImage from "../EnlargedImage";

/* ######################### All content that is related to Meru Frontend ######################### */

// Tools used in the Meru Frontend
export const MeruFrontTools = [
  { name: "AWS Amplify", link: "https://aws.amazon.com/amplify/" },
  { name: "React", link: "https://reactjs.org/" },
  { name: "CSS", link: "https://developer.mozilla.org/en-US/docs/Web/CSS" },
  {
    name: "Javascript",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  },
  { name: "npm", link: "https://www.npmjs.com/" },
  { name: "Node.js", link: "https://nodejs.org/" },
  { name: "Tailwind CSS", link: "https://tailwindcss.com/" },
  { name: "charts.js", link: "https://www.chartjs.org/" },
  { name: "recharts.js", link: "https://recharts.org/" },
  { name: "axios", link: "https://axios-http.com/" },
];

// TLDR for Meru Frontend

export const MeruFrontTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-4 text-center text-2xl font-bold text-white">
      Frontend - FAQs and Important Notes
    </h2>
    <p className="mb-6 text-center text-base font-medium">
      Key insights and FAQs from my Frontend development experience.
    </p>

    {/* Key Insights */}
    <div className="mb-6">
      <h3 className="mb-2 text-lg font-bold text-white">Key Insights</h3>
      <ul className="ml-6 list-disc space-y-2">
        <li>
          Coordinating with a team requires high levels of communication. Code
          is the language, but leadership defines momentum. Be open to learning
          and teaching.
        </li>
        <li>
          Owning the data streamlines Frontend development. Clear state
          management, prop drilling, and API calls are crucial.
        </li>
        <li>
          I am adaptable with Frontend technologies. Read the documentation, get
          support from the community, and implement!
        </li>
      </ul>
    </div>

    {/* FAQs */}
    <div className="space-y-4">
      <h3 className="mb-2 text-lg font-bold text-white">FAQs</h3>
      <div>
        <h4 className="mb-2 font-bold">
          What was your development lifecycle like?
        </h4>
        <p>
          Gather requirements, design, develop, and test features while managing
          priorities and sprint planning. Agile methodologies were integral for
          delivering solutions quickly.
        </p>
      </div>
      <div>
        <h4 className="mb-2 font-bold">
          What were some of the challenges you faced?
        </h4>
        <p>
          Leading technical teams was challenging but rewarding. I've since led
          multiple teams and delivered quality solutions, valuing teamwork and
          inclusive collaboration.
        </p>
      </div>
      <div>
        <h4 className="mb-2 font-bold">What were your biggest weaknesses?</h4>
        <p>
          Limited large-scale professional experience in technical markets. I
          balance confidence with humility, always eager to learn, grow, and
          lead effectively.
        </p>
      </div>
    </div>
  </div>
);
// Frontend overall for the first Event Card

const MeruFrontOverallText1 = (
  <>
    <p className="mt-4">
      Meru began in September 2022 as a passion project shared by me and my
      girlfriend and Product Owner, Risa. Meru, derived from "Mount Meru" or
      "Mount Sumeru", is known to be the center of the universe in many
      religions such as Buddhism and Hinduism, with the location varying from
      the North Pole to the Himalayas. There is slight controversy on the
      existence of the mountain, which I appreciate as a methaphor for the
      project.
    </p>
    <p className="mt-4">
      The Mt. Meru we have in India's Garhwal Himalayas, not to be confused with
      the religious mountain, is known to be significantly harder to climb
      compared to Mt. Everest. Less than a dozen people have reached the summit,
      and it is considered one of the most difficult mountains to climb. We
      appreciate this is a metaphor for pursuing greatness and learning from the
      journey - not the destination. This is often the case for many goals in
      life, whether it be competitive gaming or software development.
    </p>
    <p className="mt-4">
      We want to provide users with an elegant way of learning and improving
      their gameplay, providing them with guidance on their journey. While Meru
      is spans many cultures and relgions, we developed a color palette based on
      Japanese culture and traditional Japanese flowers - the{" "}
      <span className="bg-[#FCB8C8] bg-clip-text font-extrabold text-transparent">
        Sakura
      </span>
      ,{" "}
      <span className="bg-[#FFEECC] bg-clip-text font-extrabold text-transparent">
        Kiku-no-hana
      </span>
      , and{" "}
      <span className="bg-[#C1F4F5] bg-clip-text font-extrabold text-transparent">
        Ajisai
      </span>
      .
    </p>
  </>
);

const MeruFrontOverallImages1 = (
  <SmoothenlargeableImage
    src="/meru/merulanding.png"
    alt="Meru before refactor"
    title="Meru before refactor"
  />
);

export const MeruFrontOverall1 = {
  display: MeruFrontOverallText1,
  images: MeruFrontOverallImages1,
};

// Frontend Tech for the first Event Card
const MeruFrontTechText1 = (
  <>
    <p className="mt-4">
      The project uses React, from the now deprecated create-react-app template,
      because I wanted to focus on the fundamentals of React and JavaScript.
    </p>
    <div className="mt-4">
      <p className="font-bold">Building Check list for Frontend</p>
      <ul className="list-inside list-image-none text-sm">
        <li className="flex items-center">
          <span className="mr-2">{greenCheckmark}</span>
          <p>
            What are the data states being used and what is the data type
            expected to come in?
          </p>
        </li>
        <li className="flex items-center">
          <span className="mr-2">{greenCheckmark}</span>
          <p>How are we handling error responses 400, 401, 403, etc.</p>
        </li>
        <li className="flex items-center">
          <span className="mr-2">{greenCheckmark}</span>
          <p>Check responsiveness within all media queries.</p>
        </li>
      </ul>
      <p className="mt-4 font-bold">Learnings and Takeaways</p>
      <ul className="list-outside list-image-none text-sm">
        <li className="flex items-center">
          <span className="mr-2">{blueLightbulbIndicator}</span>
          <p>
            Keep up to date with HTML, CSS , and JavaScript via documentation.
            New changes can help simplify code. In this project I learned about
            the <code>article</code> , <code>dialog</code> , and{" "}
            <code>details</code> HTML elements.
          </p>
        </li>
        <li className="flex items-center">
          <span className="mr-2">{redXindicator}</span>
          <p>
            Read the documentation, engage in the community, implement, repeat.
          </p>
        </li>
      </ul>
    </div>
  </>
);

const MeruFrontTechImages1 = (
  <SmoothenlargeableImage
    src="/meru/league_component.png"
    alt="javascript code for league of legends component"
    title="League of Legends component code"
  />
);

export const MeruFrontTech1 = {
  display: MeruFrontTechText1,
  images: MeruFrontTechImages1,
};

// Frontend Thoughts for the first Event Card
const MeruFrontThoughtsText1 = (
  <>
    <p className="mt-4">
      This project was a great learning experience while I was in school. I was
      able to apply my knowledge of Data Science and Machine Learning to a
      real-world application to solve the problems many users face. My favorite
      challenge was translating my leadership and coaching experience from
      esports and marching band into the tech space. The team consisted of 5
      students and we delivered a functional project.
    </p>
    <p className="mt-4">
      Leading the frontend development meant choosing the right tools for the
      team to use. We started with React, CSS, and JavaScript, later on moving
      to tailwindcss since we understood it was a great time to learn new tools.
    </p>
    <p className="mt-4">
      Being a leader means understanding the strengths and weaknesses of your
      team. It is crucial that you listen to your team while empowering them to
      make decisions.
    </p>
  </>
);

const MeruFrontThoughtsImages1 = (
  <SmoothenlargeableImage
    src="/meru/teamwork.png"
    title="Meru before refactor"
    alt="Meru before refactor"
  />
);

export const MeruFrontThoughts1 = {
  display: MeruFrontThoughtsText1,
  images: MeruFrontThoughtsImages1,
};

// Frontend Mix for the first Event Card
const MeruFrontMixText1 = (
  <>
    <p className="mt-4">
      Providing an elegant solution that gives the user and option to learn
      without overwhelming them was the main goal. Instead of delivering dozens
      of visualizations, we built a recommendation system using LLMs to provide
      the user with the most important information.
    </p>
    <p className="mt-4">
      For every match, the user can click "Coach Me!" and it will contextualize
      the game and provide insights isolated to that match while also providing
      an overall view to their performance in regards to themselves. As an
      example, if a user hasn't played a champion in a while, the system will
      give tips on how to play against that champion. If the user underperformed
      on a chamipon that they play often, the system will provide tips on how to
      improve and what they might have done wrong.
    </p>
  </>
);

const MeruFrontMixImages1 = (
  <SmoothenlargeableImage
    src="/meru/merugg.png"
    title="Meru before refactor"
    alt="Meru before refactor"
  />
);

export const MeruFrontMix1 = {
  display: MeruFrontMixText1,
  images: MeruFrontMixImages1,
};

// Frontend overall for the second Event Card
const MeruFrontOverallText2 = (
  <>
    <p className="mt-4">
      As a codebase grows, it is important to keep the codebase clean and
      consistent. Understanding the context that I was learning frontend, I knew
      that it was important <b>not</b> to wait until I get into a big company to
      learn and appreciate good practice.
    </p>
    <p className="mt-4 flex items-center font-bold">
      <span className="ml-8 mr-2">{blueLightbulbIndicator} </span>Good practice
      is not only there to make your life easier, but to also make it easier for
      other developers to communicate.
    </p>

    {/* <p className="mt-4">
      The "Account Cards" are components that are created dynamically based on
      the data returned.
      <ul className="list-outside list-disc px-4">
        <li>Zero accounts displays the "Add Account" card by default.</li>
        <li>
          1 or more accounts displays the "Account Card" with the user's gamer
          tag, with an additional "Add Account" card at the end.
        </li>
        <li>
          At three cards, we display only "Account Cards" with no option of
          adding an account. A three account constraint is applied both in the
          frontend and backend.
        </li>
      </ul>
    </p>
    <p className="mt-4 bg-c-blue-mono bg-clip-text text-lg font-bold text-transparent">
      Creating reuseable components requires understanding the data returned,
      states demanded, and logical flow. Handling null, 0, 1, many, and edge
      cases come from an understanding of the data structures used and desired
      UX.
    </p> */}
  </>
);

const MeruFrontOverallImages2 = (
  <SmoothenlargeableImage
    src="/meru/loadingstates.png"
    title="Valorant and Riot account components"
    alt="Valorant and Riot account components"
  />
);
export const MeruFrontOverall2 = {
  display: MeruFrontOverallText2,
  images: MeruFrontOverallImages2,
};

// Frontend Tech for the second Event Card
const MeruFrontTechText2 = (
  <>
    <p className="mt-4">
      Consistency in my code is something I am constantly improving. It is easy
      to get something to work and move on to the next task, not realizing that
      a lot of the repetitive code could be abstracted into a function or a
      component. This can be solved by planning ahead before coding, or just
      reviewing your code at the end of a sprint.
    </p>
    <p className="mt-4">
      Version A of the two images is a spin animation created using tailwindcss.
      Version B uses the same animation, but I included a "Loading..." text to
      it.
    </p>
    <p className="mt-4">
      To some, these small details are not important. If you're building fast,
      you would hope you "get to it later". If you're at a large company, you
      might have a design document for reference. Regardless, this can be a bad
      habit that causes longer term maintence issues.
    </p>
    <ul className="mt-4 list-inside list-image-none">
      <li className="flex items-center">
        <span className="mr-2">{greenCheckmark}</span>
        <p>Are you handling error states in a consistent format?</p>
      </li>
      <li className="flex items-center">
        <span className="mr-2">{greenCheckmark}</span>
        <p>Are you handling loading states in a consistent format?</p>
      </li>
      <li className="flex items-center">
        <span className="mr-2">{greenCheckmark}</span>
        <p>
          Are you calling data in a consistent matter? e.g API calls in their
          own api.js -vs- having them in the component that the call is used in.
        </p>
      </li>
    </ul>
  </>
);

const MeruFrontTechImages2 = (
  <>
    <div className="flex flex-col gap-4 rounded-lg object-contain">
      <p className="text-center font-bold">IsLoading, version A</p>
      <SmoothenlargeableImage
        src="/meru/isloadingcode1.png"
        alt="IsLoading code snippet"
        title="IsLoading code snippet comparison - 1"
      />
      <p className="text-center font-bold">isLoading, version B</p>
      <SmoothenlargeableImage
        src="/meru/isloadingcode2.png"
        alt="Rare Graph"
        title="IsLoading code snippet comparison - 2"
      />
    </div>
  </>
);

export const MeruFrontTech2 = {
  display: MeruFrontTechText2,
  images: MeruFrontTechImages2,
};

// Frontend Thoughts for the second Event Card
const MeruFrontThoughtsText2 = (
  <>
    <p className="mt-4">
      Cleaning and reviewing my code reminded me of when I was a kid in school.
      My drawings would always run outside of the lines while my backpack would
      be unorganized, stuffed with papers and homework without much care.
    </p>
    <p className="mt-4">
      I re-enrolled into community college in 2019, with the purpose of applying
      myself to each class that I enrolled in. One of my first classes was a
      basic biology course that covered energy transfer, cell structure, and
      genetics. Using that knowledge helped me formulate a weight loss plan that
      took me from 290lbs to 210lbs in just 5 months. For every class I took, I
      applied the same mentality of "I'm going to get the most out of this
      class" and it paid off.
    </p>
    <ul className="mt-4 list-inside list-image-none">
      <li className="flex items-center">
        <span className="mr-2">{greenCheckmark}</span>
        <p>
          CPSC 253 - Cybersecurity & CPSC 471 Computer Communications - helped
          me debug my internal hosting and connection issues when working on
          Docker, Neo4j, WSL2 and more!
        </p>
      </li>
      <li className="flex items-center">
        <span className="mr-2">{greenCheckmark}</span>
        <p>
          Cinematography class - helped me appreciate design, staging,
          perspective, and art to a new level! I come from a music background,
          and bother of these translated well into coding by giving me
          inspiration into solving problems and managing a team.
        </p>
      </li>
      <li className="flex items-center">
        <span className="mr-2">{greenCheckmark}</span>
        <p>
          Operating Systems - helped me understand the low level of how
          computers work, and the utility that can be gained from learning
          Linux, CPU schedulers, Deadlock concepts, Virtualization, and low
          level processes. This helped me as I learned about JavaScript Event
          loops, Containzerization, and more.
        </p>
      </li>
    </ul>

    <p className="mt-4">
      Being a good person and holding yourself to a higher standard definitely
      pays off! I like to challenge myself by completing Escape Rooms with my
      girlfriend, Risa, and our friend group.
    </p>
  </>
);

const MeruFrontThoughtsImages2 = (
  <SmoothenlargeableImage
    src="/meru/EscapeRoom_KhoaPepeRisa.jpg"
    title="Meru before refactor"
    alt="Meru before refactor"
  />
);

export const MeruFrontThoughts2 = {
  display: MeruFrontThoughtsText2,
  images: MeruFrontThoughtsImages2,
};

// Frontend Mix for the second Event Card
const MeruFrontMixText2 = (
  <>
    <p className="mt-4">
      The project was a great learning experience. I was able to apply my
      knowledge of Data Science and Machine Learning to a real-world
      application. I was able to work with a team of 5 students and deliver a
      functional project.
    </p>
    <p className="mt-4">
      I was able to apply my knowledge of React, CSS, and JavaScript to build
      the frontend. I was able to apply my knowledge of Node.js, Express, and
      MongoDB to build the backend. I was able to apply my knowledge of AWS
      Amplify to deploy the application.
    </p>
  </>
);

const MeruFrontMixImages2 = (
  <SmoothenlargeableImage
    src="/meru/merulanding.png"
    title="Meru before refactor"
    alt="Meru before refactor"
  />
);

export const MeruFrontMix2 = {
  display: MeruFrontMixText2,
  images: MeruFrontMixImages2,
};

/* ######################### All content that is related to Meru Backend ######################### */

// Tools used in the Meru Backend
export const MeruBackTools = [
  { name: "FastAPI", link: "https://fastapi.tiangolo.com/" },
  { name: "Bcrypt", link: "https://pypi.org/project/bcrypt/" },
  { name: "Neo4j Graph Database", link: "https://neo4j.com/" },
  { name: "NoSQL Database", link: "https://www.mongodb.com/nosql-explained" },
  { name: "Python", link: "https://www.python.org/" },
  {
    name: "Cypher",
    link: "https://neo4j.com/developer/cypher-query-language/",
  },
  { name: "Pydantic", link: "https://pydantic-docs.helpmanual.io/" },
  { name: "Amazon Web Services", link: "https://aws.amazon.com/" },
  { name: "OpenAI API", link: "https://www.openai.com/" },
];

// TLDR for Meru Backend
export const MeruBackTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-4 text-center text-2xl font-bold text-white">
      Backend - FAQs and Important Notes
    </h2>
    <p className="mb-6 text-center text-base font-medium">
      Things I learned or things I think are important to highlight.
    </p>
    <ul className="mb-6 ml-6 list-disc space-y-2">
      <li>
        Regardless of NoSQL, SQL, or Graph databases, understanding the indexing
        and query profiling is key for optimization and performance.
        Architecture decision making is crucial for scaling and performance. A
        Graph Database was chosen for its ability to handle complex
        relationships and queries for a user-centric platform.
      </li>
      <li>
        Data retention, planning, and processing saves a headache long term!
        Storing the raw data and processing it separately allows for changes in
        the data pipeline without affecting the user experience.
      </li>
      <li>
        Currently hosted on AWS using Fargate, VPC, ECS, EC2, CloudWatch, and
        S3.
      </li>
    </ul>
    <h3 className="mb-4 text-center text-2xl font-bold text-white">FAQs</h3>
    <div className="space-y-4">
      <div>
        <p className="text-lg font-bold">
          What are the statistical models deployed?
        </p>
        <p>
          We currently have rule-based recommendations, collaborative filtering,
          and content-based filtering models. We use them to recommend them new
          champions to play, item builds, and which data points to display that
          will help the user improve.
        </p>
      </div>
      <div>
        <p className="text-lg font-bold">What are your next steps?</p>
        <p>
          Adding in functionality for Valorant requires a full production key
          approval from Riot Games. We are also working on improving the core
          features of the platform with a hyper-focus on our current user base.
          The application is not exclusive in nature, but for now it is access
          code invite only so we can focus on building core features for our
          users.
        </p>
      </div>
    </div>
  </div>
);
// Backend overall for the first Event Card
const MeruBackOverallText1 = (
  <>
    <p className="mt-4">
      Riot Games API provides developers with initial development keys, allowing
      200 requests per minute. Setting up a user's account requires 4 API calls,
      with an additional call for every 100 games played and another for each
      match played.
    </p>
    <p className="mt-4">
      Each match data API call retrieves information for 10 players, enabling
      data collection for 10 users with a single request. This efficient data
      retrieval facilitated a user-centric platform development.
    </p>
    <p className="mt-4">
      We built user statistics based on their game history and comparison
      statistics using opponent data, covering 90% of the website's
      functionality.
    </p>
    <p className="mt-4">
      The final 10% involved creating statistical models to directly benefit
      players, requiring additional game data downloads.
    </p>
    <p className="bg-c-blue-mono bg-clip-text font-bold text-transparent">
      To optimize performance, we saved all matches in a MongoDB collection and
      only called the Riot API for new matches. This approach reduces API calls
      and shortens the wait time for users to receive their personalized
      statistics, enhancing their experience.
    </p>
  </>
);

const MeruBackOverallImage1 = (
  <>
    <img
      className="w-3/4 rounded-lg object-contain"
      src="/meru/matchesflow.png"
      title="Match Ingestion Flow Diagram"
      alt="flow diagram"
    />
  </>
);

export const MeruBackOverall1 = {
  display: MeruBackOverallText1,
  images: MeruBackOverallImage1,
};

// Backend Tech for the first Event Card
const MeruBackTechText1 = (
  <>
    <p className="mt-4">
      Riot Games API provides developers with initial development keys, allowing
      200 requests per minute. Setting up a user's account requires 4 API calls,
      with an additional call for every 100 games played and another for each
      match played.
    </p>
    <p className="mt-4">
      Each match data API call retrieves information for 10 players, enabling
      data collection for 10 users with a single request. This efficient data
      retrieval facilitated a user-centric platform development.
    </p>
    <p className="mt-4">
      We built user statistics based on their game history and comparison
      statistics using opponent data, covering 90% of the website's
      functionality.
    </p>
    <p className="mt-4">
      The final 10% involved creating statistical models to directly benefit
      players, requiring additional game data downloads.
    </p>
    <p className="bg-c-blue-mono bg-clip-text font-bold text-transparent">
      To optimize performance, we saved all matches in a MongoDB collection and
      only called the Riot API for new matches. This approach reduces API calls
      and shortens the wait time for users to receive their personalized
      statistics, enhancing their experience.
    </p>
  </>
);

const MeruBackTechImage1 = (
  <>
    <img
      className="w-3/4 rounded-lg object-contain"
      src="/meru/matchesflow.png"
      title="Match Ingestion Flow Diagram"
      alt="flow diagram"
    />
  </>
);

export const MeruBackTech1 = {
  display: MeruBackTechText1,
  images: MeruBackTechImage1,
};

// Backend Thoughts for the first Event Card

const MeruBackThoughtsText1 = (
  <>
    <p className="mt-4">
      Riot Games API provides developers with initial development keys, allowing
      200 requests per minute. Setting up a user's account requires 4 API calls,
      with an additional call for every 100 games played and another for each
      match played.
    </p>
    <p className="mt-4">
      Each match data API call retrieves information for 10 players, enabling
      data collection for 10 users with a single request. This efficient data
      retrieval facilitated a user-centric platform development.
    </p>
    <p className="mt-4">
      We built user statistics based on their game history and comparison
      statistics using opponent data, covering 90% of the website's
      functionality.
    </p>
    <p className="mt-4">
      The final 10% involved creating statistical models to directly benefit
      players, requiring additional game data downloads.
    </p>
    <p className="bg-c-blue-mono bg-clip-text font-bold text-transparent">
      To optimize performance, we saved all matches in a MongoDB collection and
      only called the Riot API for new matches. This approach reduces API calls
      and shortens the wait time for users to receive their personalized
      statistics, enhancing their experience.
    </p>
  </>
);

const MeruBackThoughtsImage1 = (
  <>
    <img
      className="w-3/4 rounded-lg object-contain"
      src="/meru/raregraph.png"
      title="Match Ingestion Flow Diagram"
      alt="flow diagram"
    />
  </>
);

export const MeruBackThoughts1 = {
  display: MeruBackThoughtsText1,
  images: MeruBackThoughtsImage1,
};

// Backend Mix for the first Event Card

const MeruBackMixText1 = (
  <>
    <p className="mt-4">
      Riot Games API provides developers with initial development keys, allowing
      200 requests per minute. Setting up a user's account requires 4 API calls,
      with an additional call for every 100 games played and another for each
      match played.
    </p>
    <p className="mt-4">
      Each match data API call retrieves information for 10 players, enabling
      data collection for 10 users with a single request. This efficient data
      retrieval facilitated a user-centric platform development.
    </p>
    <p className="mt-4">
      We built user statistics based on their game history and comparison
      statistics using opponent data, covering 90% of the website's
      functionality.
    </p>
    <p className="mt-4">
      The final 10% involved creating statistical models to directly benefit
      players, requiring additional game data downloads.
    </p>
    <p className="bg-c-blue-mono bg-clip-text font-bold text-transparent">
      To optimize performance, we saved all matches in a MongoDB collection and
      only called the Riot API for new matches. This approach reduces API calls
      and shortens the wait time for users to receive their personalized
      statistics, enhancing their experience.
    </p>
  </>
);

const MeruBackMixImage1 = (
  <>
    <img
      className="w-3/4 rounded-lg object-contain"
      src="/meru/commongraph.png"
      title="Match Ingestion Flow Diagram"
      alt="flow diagram"
    />
  </>
);
export const MeruBackMix1 = {
  display: MeruBackMixText1,
  images: MeruBackMixImage1,
};

const MeruBackOverallText2 = (
  <>
    <p className="mt-4">
      The heart of our application leverages an AI/ML model to deliver tailored
      insights for users. For instance, if a user loses five matches
      consecutively, our model can run a comparative analysis and provide either
      a reassuring message or critical feedback based on the situation.
    </p>
    <p className="mt-4">
      To achieve this personalized experience, we actively seek user feedback to
      understand their needs and combine it with our esports coaching expertise.
      This custom approach ensures users feel genuinely supported, with data
      tailored to their specific requests. We represent this feedback through a{" "}
      <span className="rounded-lg bg-gray-500 p-1">common</span>,{" "}
      <span className="rounded-lg bg-c-pink-mono p-1 text-c-black">rare</span>,
      or{" "}
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">custom</span>{" "}
      graph and text, which we plan to enhance using Generative AI.
    </p>
    <p className="mt-4">
      Each graph is generated through detailed data analysis, with colors
      indicating the depth of feedback. Custom models will be available as a
      freemium feature. Users can occasionally access custom graphs for free or
      unlock up to six custom graphs per match via the
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">
        Coach Me!
      </span>{" "}
      button.
    </p>
    <p className="mt-4">
      As we scale, each graph will be abstracted and reused for other users.
      However, the exclusivity will be maintained through personalized AI
      recommendation models tailored to each user.
    </p>
  </>
);

const MeruBackOverallImages2 = (
  <>
    <div className="w-1/3 lg:absolute lg:right-52 lg:top-40 lg:z-30 lg:h-1/2 lg:w-1/3 lg:rounded-lg lg:object-contain">
      {" "}
      <SmoothenlargeableImage
        src="/meru/commongraph.png"
        alt="Common Graph"
        title="Common Graph"
      />
    </div>
    <div className="w-1/3 lg:absolute lg:right-40 lg:top-28 lg:z-20 lg:h-1/2 lg:w-1/3 lg:rounded-lg lg:object-contain">
      <SmoothenlargeableImage
        src="/meru/raregraph.png"
        alt="Rare Graph"
        title="Rare Graph"
      />
    </div>
    <div className="lg:z-21 w-1/3 lg:absolute lg:right-28 lg:top-16 lg:h-1/2 lg:w-1/3 lg:rounded-lg lg:object-contain">
      <SmoothenlargeableImage
        src="/meru/customgraph.png"
        alt="Custom Graph"
        title="Custom Graph"
      />
    </div>
  </>
);

export const MeruBackOverall2 = {
  display: MeruBackOverallText2,
  images: MeruBackOverallImages2,
};

const MeruBackTechText2 = (
  <>
    <p className="mt-4">
      The heart of our application leverages an AI/ML model to deliver tailored
      insights for users. For instance, if a user loses five matches
      consecutively, our model can run a comparative analysis and provide either
      a reassuring message or critical feedback based on the situation.
    </p>
    <p className="mt-4">
      To achieve this personalized experience, we actively seek user feedback to
      understand their needs and combine it with our esports coaching expertise.
      This custom approach ensures users feel genuinely supported, with data
      tailored to their specific requests. We represent this feedback through a{" "}
      <span className="rounded-lg bg-gray-500 p-1">common</span>,{" "}
      <span className="rounded-lg bg-c-pink-mono p-1 text-c-black">rare</span>,
      or{" "}
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">custom</span>{" "}
      graph and text, which we plan to enhance using Generative AI.
    </p>
    <p className="mt-4">
      Each graph is generated through detailed data analysis, with colors
      indicating the depth of feedback. Custom models will be available as a
      freemium feature. Users can occasionally access custom graphs for free or
      unlock up to six custom graphs per match via the
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">
        Coach Me!
      </span>{" "}
      button.
    </p>
    <p className="mt-4">
      As we scale, each graph will be abstracted and reused for other users.
      However, the exclusivity will be maintained through personalized AI
      recommendation models tailored to each user.
    </p>
  </>
);

const MeruBackTechImages2 = (
  <SmoothenlargeableImage
    src="/meru/commongraph.png"
    alt="Common Graph"
    title="Common Graph"
  />
);

export const MeruBackTech2 = {
  display: MeruBackTechText2,
  images: MeruBackTechImages2,
};

const MeruBackThoughtsText2 = (
  <>
    <p className="mt-4">
      The heart of our application leverages an AI/ML model to deliver tailored
      insights for users. For instance, if a user loses five matches
      consecutively, our model can run a comparative analysis and provide either
      a reassuring message or critical feedback based on the situation.
    </p>
    <p className="mt-4">
      To achieve this personalized experience, we actively seek user feedback to
      understand their needs and combine it with our esports coaching expertise.
      This custom approach ensures users feel genuinely supported, with data
      tailored to their specific requests. We represent this feedback through a{" "}
      <span className="rounded-lg bg-gray-500 p-1">common</span>,{" "}
      <span className="rounded-lg bg-c-pink-mono p-1 text-c-black">rare</span>,
      or{" "}
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">custom</span>{" "}
      graph and text, which we plan to enhance using Generative AI.
    </p>
    <p className="mt-4">
      Each graph is generated through detailed data analysis, with colors
      indicating the depth of feedback. Custom models will be available as a
      freemium feature. Users can occasionally access custom graphs for free or
      unlock up to six custom graphs per match via the
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">
        Coach Me!
      </span>{" "}
      button.
    </p>
    <p className="mt-4">
      As we scale, each graph will be abstracted and reused for other users.
      However, the exclusivity will be maintained through personalized AI
      recommendation models tailored to each user.
    </p>
  </>
);

const MeruBackThoughtsImages2 = (
  <SmoothenlargeableImage
    src="/meru/commongraph.png"
    alt="Common Graph"
    title="Common Graph"
  />
);

export const MeruBackThoughts2 = {
  display: MeruBackThoughtsText2,
  images: MeruBackThoughtsImages2,
};

const MeruBackMixText2 = (
  <>
    <p className="mt-4">
      The heart of our application leverages an AI/ML model to deliver tailored
      insights for users. For instance, if a user loses five matches
      consecutively, our model can run a comparative analysis and provide either
      a reassuring message or critical feedback based on the situation.
    </p>
    <p className="mt-4">
      To achieve this personalized experience, we actively seek user feedback to
      understand their needs and combine it with our esports coaching expertise.
      This custom approach ensures users feel genuinely supported, with data
      tailored to their specific requests. We represent this feedback through a{" "}
      <span className="rounded-lg bg-gray-500 p-1">common</span>,{" "}
      <span className="rounded-lg bg-c-pink-mono p-1 text-c-black">rare</span>,
      or{" "}
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">custom</span>{" "}
      graph and text, which we plan to enhance using Generative AI.
    </p>
    <p className="mt-4">
      Each graph is generated through detailed data analysis, with colors
      indicating the depth of feedback. Custom models will be available as a
      freemium feature. Users can occasionally access custom graphs for free or
      unlock up to six custom graphs per match via the
      <span className="rounded-lg bg-yellow-300 p-1 text-c-black">
        Coach Me!
      </span>{" "}
      button.
    </p>
    <p className="mt-4">
      As we scale, each graph will be abstracted and reused for other users.
      However, the exclusivity will be maintained through personalized AI
      recommendation models tailored to each user.
    </p>
  </>
);

const MeruBackMixImages2 = (
  <>
    <div className="absolute right-52 top-40 z-30 h-1/2 w-1/3 rounded-lg object-contain">
      {" "}
      <SmoothenlargeableImage
        src="/meru/commongraph.png"
        alt="Common Graph"
        title="Common Graph"
      />
    </div>
    <div className=" absolute right-40 top-28 z-20 h-1/2 w-1/3 rounded-lg object-contain">
      <SmoothenlargeableImage
        src="/meru/raregraph.png"
        alt="Rare Graph"
        title="Rare Graph"
      />
    </div>
    <div className=" z-21 absolute right-28 top-16 h-1/2 w-1/3 rounded-lg object-contain">
      <SmoothenlargeableImage
        src="/meru/customgraph.png"
        alt="Custom Graph"
        title="Custom Graph"
      />
    </div>
  </>
);

export const MeruBackMix2 = {
  display: MeruBackMixText2,
  images: MeruBackMixImages2,
};

export const MeruBackImages2 = (
  <>
    <div className="relative w-3/4">
      <img
        className="absolute left-0 top-28 z-30 w-full rounded-lg object-contain"
        src="/meru/commongraph.png"
        title="Common Graph"
        alt="Graph with a gray border"
      />
      <img
        className="absolute left-4 top-20 z-20 w-full rounded-lg object-contain"
        src="/meru/raregraph.png"
        title="Rare Graph"
        alt="Graph with a pink border"
      />
      <img
        className="absolute left-8 top-12 z-10 w-full rounded-lg object-contain"
        src="/meru/customgraph.png"
        title="Custom Graph"
        alt="Graph with a yellow border"
      />
    </div>
  </>
);

/* ######################### All content that is related to Meru AI/ML ######################### */

// Tools used in the Meru AI/ML
export const MeruAIMLTools = [
  { name: "PyTorch", link: "https://pytorch.org/" },
  { name: "TensorFlow", link: "https://www.tensorflow.org/" },
  { name: "Scikit-learn", link: "https://scikit-learn.org/stable/" },
  { name: "Pandas", link: "https://pandas.pydata.org/" },
  { name: "Jupyter Notebook", link: "https://jupyter.org/" },
  { name: "OpenAI", link: "https://www.openai.com/" },
  { name: "Qdrant", link: "https://qdrant.ai/" },
  { name: "Neo4j", link: "https://neo4j.com/" },
  { name: "Python", link: "https://www.python.org/" },
  { name: "AWS", link: "https://aws.amazon.com/" },
];

// TLDR for Meru AI/ML
export const MeruAIMLTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-4 px-4 text-center text-xl font-bold text-white">
      AI / Data Science - FAQs and Important Notes
    </h2>
    <p className="mb-6 text-center text-base font-medium">
      Things I learned or things I think are important to highlight.
    </p>
    <ul className="mb-6 ml-6 list-disc space-y-2">
      <li>
        Innovation and simplification are key. I chose to use existing LLM
        models to speed up the process, but I took creative liberties to create
        a custom architecture.
      </li>
      <li>
        Remain aware of new technologies and new possibilities. In a rapidly
        changing field, it's important to stay up to date.
      </li>
    </ul>
    <h3 className="mb-4 text-center text-2xl font-bold text-white">FAQs</h3>
    <div className="space-y-4">
      <div>
        <p className="text-lg font-bold">What was your process?</p>
        <p>
          Coming from an academic background, I read research papers and kept up
          to date with the latest technologies and models available. Having a
          strong understanding of Natural Language Process and Machine Learning
          helped me focus more on building a strong system architecture, as
          opposed to spending time on prompt tuning and model training.
        </p>
      </div>
      <div>
        <p className="text-lg font-bold">What is your experience in AI/ML?</p>
        <p>
          My main focus in University was AI/ML, having spent summers with
          Professors working on Machine Learning based research projects. My
          courses included Differential Equations, Linear Algebra, Discrete
          Math, and Probability Theory, which inspired me to dive deep into the
          low level math behind AI/ML. I have also completed additional
          programs, such as{" "}
          <a
            href="https://aws.amazon.com/machine-learning/scholarship/"
            target="_blank"
            rel="noreferrer"
            className="font-bold text-c-blue-mono"
          >
            AWS Machine Learning Scholarship
          </a>
          .
        </p>
      </div>
    </div>
  </div>
);

const MeruAIMLOverallText1 = (
  <>
    <p className="mt-4">
      Through my experience in research and academia, I have developed a strong
      fundamental process on how to approach AI/ML within a project.
      <ul className="list-inside list-image-none text-sm">
        <li className="flex items-center">
          <span className="mr-2">{redXindicator}</span>
          <p>
            Do not slap on AI/ML models for the sake of it. Understand your
            data, your available pipelines, and the problem you are trying to
            solve.
          </p>
        </li>
      </ul>
    </p>
  </>
);

const MeruAIMLOverallImages1 = (
  <SmoothenlargeableImage
    src="/meru/merulanding.png"
    title="Meru before refactor"
    alt="Meru before refactor"
  />
);

export const MeruAIMLOverall1 = {
  display: MeruAIMLOverallText1,
  images: MeruAIMLOverallImages1,
};

/* ######################### All content that is related to Meru More ######################### */

export const MeruMoreTools = [
  { name: "Vercel", link: "https://vercel.com/" },
  { name: "Docker", link: "https://www.docker.com/" },
  { name: "Replit", link: "https://replit.com/" },
  { name: "AWS", link: "https://aws.amazon.com/" },
  { name: "Azure", link: "https://azure.microsoft.com/en-us/" },
  { name: "Google Cloud", link: "https://cloud.google.com/" },
  { name: "Fargate", link: "https://aws.amazon.com/fargate/" },
];

export const MeruMoreTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-4 text-center text-2xl font-bold text-white">
      AI / Data Science - FAQs and Important Notes
    </h2>
    <p className="mb-6 text-center text-base font-medium">
      Things I learned or things I think are important to highlight.
    </p>
    <ul className="mb-6 ml-6 list-disc space-y-2">
      <li>
        Innovation and simplification are key. I chose to use existing LLM
        models to speed up the process, but I took creative liberties to create
        a custom architecture.
      </li>
      <li>
        Remain aware of new technologies and new possibilities. In a rapidly
        changing field, it's important to stay up to date.
      </li>
    </ul>
    <h3 className="mb-4 text-center text-2xl font-bold text-white">FAQs</h3>
    <div className="space-y-4">
      <div>
        <p className="text-lg font-bold">What was your process?</p>
        <p>
          Coming from an academic background, I read research papers and kept up
          to date with the latest technologies and models available. Having a
          strong understanding of Natural Language Process and Machine Learning
          helped me focus more on building a strong system architecture, as
          opposed to spending time on prompt tuning and model training.
        </p>
      </div>
      <div>
        <p className="text-lg font-bold">What is your experience in AI/ML?</p>
        <p>
          My main focus in University was AI/ML, having spent summers with
          Professors working on Machine Learning based research projects. My
          courses included Differential Equations, Linear Algebra, Discrete
          Math, and Probability Theory, which inspired me to dive deep into the
          low-level math behind AI/ML. I have also completed additional
          programs, such as AWS Machine Learning Scholarship.
        </p>
      </div>
    </div>
  </div>
);

const MeruMoreOverallText1 = (
  <>
    <p className="mt-8">
      Some data sources were not available through APIs, namely 3CX - a VOIP
      system, so my teammate,{" "}
      <a
        href="https://www.linkedin.com/in/paramteraiya/?originalSubdomain=in"
        target="_blank"
        rel="noreferrer"
        className="font-bold text-c-blue-mono  hover:text-c-blue"
      >
        Param Teraiya
      </a>{" "}
      created a web scraper that automated the process of logging in, navigating
      to the call recordings, and downloading the audio files.
      <br />
    </p>
    <p className="mt-2">
      I already had a Jupyter Notebook that converted audio files into
      transcripts using{" "}
      <a
        href="https://platform.openai.com/docs/guides/speech-to-text"
        referrerPolicy="noreferrer"
        target="_blank"
        rel="noreferrer"
        className="font-bold text-c-blue-mono  hover:text-c-blue"
      >
        OpenAI's Whisper API
      </a>{" "}
      but it needed to be automated and improved. After transforming the Jupyter
      Notebook into production code, hosting it on{" "}
      <a
        href="https://replit.com/~"
        referrerPolicy="noreferrer"
        target="_blank"
        rel="noreferrer"
        className="font-bold text-c-blue-mono  hover:text-c-blue "
      >
        Replit
      </a>{" "}
      for easy access, we were able to automate the process of converting audio
      files to text.
      <br />
    </p>
    <p className="mt-2">
      <span className="bg-red-500 bg-clip-text font-bold text-transparent">
        But OpenAI did not have asynchronous support for Whisper!
      </span>
      <br />
      To fix that, we created our own asynchronous wrapper, shown in the
      attached image.
    </p>

    <p className="mt-2">
      The final result was a tool that:
      <ul className="mb-4 list-outside list-disc px-4">
        <li>Automated the process of downloading audio files.</li>
        <li>
          Reduce processing time of 1000 phone calls from 30 minutes to 2
          minutes.
        </li>
        <li>
          API endpoint connected to our website so users can input their 3CX
          credentials and begin downloading their phone calls
        </li>
      </ul>
    </p>
  </>
);

const MeruMoreOverallImages1 = (
  <SmoothenlargeableImage
    src="/bloom/codeimage-snippet_27.svg"
    title="OpenAI Whisper Wrapper"
    alt="OpenAI Whisper Wrapper"
  />
);

export const MeruMoreOverall1 = {
  display: MeruMoreOverallText1,
  images: MeruMoreOverallImages1,
};
