import { React, useEffect, useRef, useState } from "react";
//import logo from './logo.png';
import AudienceBar from "../components/AudienceBar";
import ProjectHeadline from "../components/Headline/ProjectHeadline";
import FinBack from "../components/finbuddy/finback";
import FinData from "../components/finbuddy/findata";
import FinFront from "../components/finbuddy/finfront";
import FinMore from "../components/finbuddy/finmore";
const sections = [
  {
    id: "situation",
    title: "Stay accountable to your finances!",
    content:
      "Growing up in a low financial literacy household, I learned about building financial habits the hard way. I wanted to create a tool that would help people like me stay accountable to their finances. Leveraging AI/ML, Finance Buddy streamlines personal financial insights to the user with a contextual understanding of who they are.",
  },
  {
    id: "task",
    title: "Creating a full stack application",
    content:
      "An overwhelming amount of charts and graphs can be confusing and unhelpful. By taking inspiration from the luxury fashion industry, I wanted to build a tool that was both elegant and friendly. The goal of the application to it elevate the user's mindset and experience within their financial journey in an exclusive and personalized way.",
  },
  {
    id: "action",
    title: "Design, Develop, Deliver",
    content:
      "Using Plaid Python SDK, we are able to connect to over 10,000+ financial institutions to pull in user data. React and Tailwind CSS were used to build the frontend, while FastAPI and PostgreSQL were used to build the backend. The AI/ML models were built using PyTorch and Scikit-learn. The application was deployed using Docker and AWS.",
  },
  {
    id: "result",
    title: "Results",
    content:
      "The application resulted in the onboarding process being personalized and engaging. The user can connect up to three financial institutions and receive personalized insights based on their financial data. The application is currently in beta and is being tested by a select group of users with plans to launch in the coming months.",
  },
  {
    id: "role",
    title: "Applied AI Full Stack Developer",
    content:
      "As an Applied AI Full Stack Developer, I was responsible for the entire software development lifecycle. The challenges for this application helped me learn about 2FA, OAuth, and integrating APIs into a data ingestion pipeline. I was cautious to not just implement AI for the sake of it, but to ensure that it was providing value to the user. For the frontend, I began with React and CSS, later moving to tailwindcss for a more streamlined development experience.",
  },
];

const Finance = () => {
  const [currentDev, setCurrentDev] = useState("frontend");
  const overflowContainerRef = useRef(null);
  useEffect(() => {
    if (overflowContainerRef.current) {
      overflowContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentDev]);
  const imgComponent = (
    <img
      className="rounded-lg shadow-lg shadow-c-white"
      alt=""
      src="/financeai/RenewedLanding.png"
    ></img>
  );
  return (
    <div className="mx-auto h-full w-5/6 px-4 max-md:w-full">
      <ProjectHeadline
        mainText={"Finance Buddy."}
        subText={"Financial insights for the everyday person."}
        imgComponent={imgComponent}
        sections={sections}
      />
      <section className="m-auto mt-12 p-2 max-md:mt-4 max-md:p-0">
        <AudienceBar currentDev={currentDev} setCurrentDev={setCurrentDev} />
      </section>
      <section
        id="info"
        ref={overflowContainerRef}
        className="h-3/4 overflow-y-auto overscroll-contain p-2"
      >
        {currentDev === "frontend" && <FinFront />}
        {currentDev === "backend" && <FinBack />}
        {currentDev === "datascience" && <FinData />}
        {currentDev === "more" && <FinMore />}
      </section>
    </div>
  );
};

export default Finance;
