import { React, useState } from "react";
import EventCard from "../EventCard";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import {
  BloomAIMLOverall1,
  BloomAIMLOverall2,
  BloomAIMLOverall3,
  BloomAIMLTools,
} from "./content";
const BloomData = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const tldrContent = (
    <div className="text-c-black">
      <h2 className="mb-2 px-4 text-center text-xl font-bold">
        AI / Data Science - FAQs and Important Notes.
      </h2>
      <p className="mb-4 text-center text-xs font-bold">
        Things I learned or things I think are important to highlight.
      </p>
      <ul className="mb-4 list-outside list-disc p-4">
        <li>
          Innovation and simplication are key. I chose to use existing LLM
          models to speed up the process, but I took creative liberties to
          create a custom architecture.
        </li>
        <li>
          Remain aware of new technologies and new possibilities. In a rapidly
          changing field, it's important to stay up to date.
        </li>
      </ul>
      <h3 className="mb-2 text-center text-2xl font-bold">FAQs</h3>
      <p className="text-center text-lg font-bold">What was your process?</p>
      <p>
        Coming from an academic background, I read research papers and kept up
        to date with the latest technologies and models available. Having a
        strong understanding of Natural Language Process and Machine Learning
        helped me focus more on building a strong system architecture, as
        opposed to spending time on prompt tuning and model training.
      </p>
      <p className="text-center text-lg font-bold">
        What is your experience in AI/ML?
      </p>
      <p>
        My main focus in University was AI/ML, having spent summers with
        Professors working on Machine Learning based research projects. My
        courses included Differential Equations, Linear Algebra, Discrete Math,
        and Probability Theory, which inspired me to dive deep into the low
        level math behind AI/ML. I have also completed additional programs, such
        as{" "}
        <a
          href="https://aws.amazon.com/machine-learning/scholarship/"
          target="_blank"
          rel="noreferrer"
          className="font-bold underline"
        >
          AWS Machine Learning Scholarship
        </a>
        .
      </p>
    </div>
  );

  return (
    <div id="events" className="events flex w-full flex-col">
      <Tooling tools={BloomAIMLTools} />

      <TLDRButton
        onClick={handleOpenModal}
        buttonText="AI/ Data Science in a nutshell"
      />

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={tldrContent}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <EventCard
          title="Fundamentals First"
          subtitle="I made good use of my background in Research and Academia."
          overallContent={BloomAIMLOverall1}
        />

        <EventCard
          title="Preparing the data for embeddings."
          subtitle="Data analysis and cleaning."
          overallContent={BloomAIMLOverall2}
        />

        <EventCard
          title="The AI Engine"
          subtitle="I optimized a business and saved the CEO's time."
          overallContent={BloomAIMLOverall3}
        />
      </div>
    </div>
  );
};

export default BloomData;
