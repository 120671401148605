import React, { useState } from "react";

const ProjectHeadline = ({ mainText, subText, imgComponent, sections }) => {
  const [currentDev, setCurrentDev] = useState("frontend");
  const [activeSection, setActiveSection] = useState(null);

  const handleToggleSection = (id) => {
    setActiveSection((prevActiveSection) =>
      prevActiveSection === id ? null : id,
    );
  };

  const handleSummaryClick = (e, id) => {
    e.preventDefault(); // Prevent default <details> behavior
    handleToggleSection(id);
  };

  return (
    <section className="mx-auto flex w-full flex-col-reverse gap-8 xl:flex-row">
      <article className="m-auto flex flex-col lg:w-3/4">
        <h1 className="text-center text-2xl font-bold lg:text-4xl">
          {mainText}
        </h1>
        <p className="mb-4 text-center text-sm">{subText}</p>
        {sections.map((section) => (
          <div className="mt-4">
            <h2 className="font-semibold text-c-white lg:text-left lg:text-xl">
              {section.title}
            </h2>
            <p className="text-sm text-c-white">{section.content}</p>
          </div>
        ))}
      </article>
      <div className="m-auto flex lg:w-3/4">{imgComponent}</div>
    </section>
  );
};

export default ProjectHeadline;
