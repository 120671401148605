import React from "react";

const TLDRButton = ({ onClick, buttonText }) => {
  return (
    <div className="my-6 flex justify-center">
      <button
        className="w-full max-w-md transform rounded-md bg-gradient-to-r
                   from-blue-500 to-pink-500 px-6 py-3
                   text-base font-bold text-white
                   transition-all duration-300 ease-in-out
                   hover:scale-105 hover:from-blue-600 hover:to-pink-600 hover:shadow-lg
                   focus:outline-none focus:ring-2
                   focus:ring-blue-500 focus:ring-offset-2"
        onClick={onClick}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default TLDRButton;
