import { React, useState } from "react";
import EventCard from "../EventCard";
import Modal from "../Modal";
import TLDRButton from "../TLDR/tldrbutton";
import Tooling from "../Tools";
import {
  BloomMoreOverall1,
  BloomMoreOverall2,
  BloomMoreTLDR,
  BloomMoreTools,
} from "./content";
const BloomMore = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div id="events" className="events flex w-full flex-col">
      <Tooling tools={BloomMoreTools} />

      <TLDRButton onClick={handleOpenModal} buttonText="More in a nutshell" />

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={BloomMoreTLDR}
      />

      <div id="eventcards" className="mt-8 flex flex-col gap-6">
        <EventCard
          title="Internal Tooling"
          subtitle="Creating tools to help the team and stakeholders."
          overallContent={BloomMoreOverall1}
        />

        <EventCard
          title="Independent API Ingestion pipelines"
          subtitle="SSO, OAuth 2.0, credential handling, reuseable code"
          overallContent={BloomMoreOverall2}
        />
      </div>
    </div>
  );
};

export default BloomMore;
