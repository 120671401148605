import React, { useCallback, useEffect, useRef, useState } from "react";

const SmoothenlargeableImage = ({ src, alt, title }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const dragStartRef = useRef({ x: 0, y: 0 });

  const resetZoomAndPosition = useCallback(() => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  }, []);

  const handleZoom = useCallback(
    (event) => {
      event.preventDefault();
      const container = containerRef.current;
      const image = container.querySelector("img");

      const rect = container.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      let scaleChange;
      if (event.deltaY) {
        // Mouse wheel
        scaleChange = -event.deltaY * 0.001;
      } else if (event.scale) {
        // Pinch gesture
        scaleChange = event.scale - 1;
      } else {
        return;
      }

      const newScale = Math.min(Math.max(1, scale + scaleChange), 5);

      if (newScale !== scale) {
        const scaleRatio = newScale / scale;

        const imageCenterX = container.clientWidth / 2 + position.x;
        const imageCenterY = container.clientHeight / 2 + position.y;

        const distX = mouseX - imageCenterX;
        const distY = mouseY - imageCenterY;

        const newX = position.x - distX * (scaleRatio - 1);
        const newY = position.y - distY * (scaleRatio - 1);

        setScale(newScale);
        setPosition((prev) => {
          return constrainPosition(newX, newY, newScale, container, image);
        });
      }
    },
    [scale, position],
  );

  const constrainPosition = (x, y, currentScale, container, image) => {
    const maxX = Math.max(
      0,
      (image.width * currentScale - container.clientWidth) / 2,
    );
    const maxY = Math.max(
      0,
      (image.height * currentScale - container.clientHeight) / 2,
    );
    return {
      x: Math.min(Math.max(x, -maxX), maxX),
      y: Math.min(Math.max(y, -maxY), maxY),
    };
  };

  const handleEnlarge = useCallback(() => {
    setIsEnlarged(true);
    resetZoomAndPosition();
    document.body.style.overflow = "hidden";
  }, [resetZoomAndPosition]);

  const handleClose = useCallback(() => {
    setIsEnlarged(false);
    resetZoomAndPosition();
    document.body.style.overflow = "";
  }, [resetZoomAndPosition]);

  const handleMouseDown = useCallback(
    (e) => {
      if (scale > 1) {
        setDragging(true);
        dragStartRef.current = {
          x: e.clientX - position.x,
          y: e.clientY - position.y,
        };
      }
    },
    [position, scale],
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (dragging && scale > 1) {
        const container = containerRef.current;
        const image = container.querySelector("img");
        const newX = e.clientX - dragStartRef.current.x;
        const newY = e.clientY - dragStartRef.current.y;
        const constrained = constrainPosition(
          newX,
          newY,
          scale,
          container,
          image,
        );
        setPosition(constrained);
      }
    },
    [dragging, scale],
  );

  const handleMouseUp = useCallback(() => {
    setDragging(false);
  }, []);

  const handleTouchStart = useCallback(
    (e) => {
      if (e.touches.length === 1 && scale > 1) {
        setDragging(true);
        dragStartRef.current = {
          x: e.touches[0].clientX - position.x,
          y: e.touches[0].clientY - position.y,
        };
      }
    },
    [position, scale],
  );

  const handleTouchMove = useCallback(
    (e) => {
      if (dragging && scale > 1 && e.touches.length === 1) {
        const container = containerRef.current;
        const image = container.querySelector("img");
        const newX = e.touches[0].clientX - dragStartRef.current.x;
        const newY = e.touches[0].clientY - dragStartRef.current.y;
        const constrained = constrainPosition(
          newX,
          newY,
          scale,
          container,
          image,
        );
        setPosition(constrained);
      }
    },
    [dragging, scale],
  );

  const handleTouchEnd = useCallback(() => {
    setDragging(false);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    if (isEnlarged) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isEnlarged, handleKeyDown]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && isEnlarged) {
      container.addEventListener("wheel", handleZoom, { passive: false });
      container.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      container.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      container.addEventListener("touchend", handleTouchEnd);
      container.addEventListener("gesturestart", handleZoom, {
        passive: false,
      });
      container.addEventListener("gesturechange", handleZoom, {
        passive: false,
      });
      return () => {
        container.removeEventListener("wheel", handleZoom);
        container.removeEventListener("touchstart", handleTouchStart);
        container.removeEventListener("touchmove", handleTouchMove);
        container.removeEventListener("touchend", handleTouchEnd);
        container.removeEventListener("gesturestart", handleZoom);
        container.removeEventListener("gesturechange", handleZoom);
      };
    }
  }, [
    isEnlarged,
    handleZoom,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  ]);

  return (
    <>
      <div className="group relative m-auto cursor-zoom-in">
        <img
          ref={imageRef}
          className="m-auto w-full transform rounded-lg object-contain transition-transform duration-300 ease-in-out hover:scale-105"
          src={src}
          alt={alt}
          title={title}
          onClick={handleEnlarge}
        />
        <div className="absolute right-2 top-2 lg:right-4 lg:top-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-8 rounded-full bg-black bg-opacity-50 p-1 text-white lg:h-12"
          >
            <polyline points="15 3 21 3 21 9"></polyline>
            <polyline points="9 21 3 21 3 15"></polyline>
            <line x1="21" y1="3" x2="14" y2="10"></line>
            <line x1="3" y1="21" x2="10" y2="14"></line>
          </svg>
        </div>
      </div>
      {isEnlarged && (
        <div
          className="fixed inset-0 z-50 flex touch-none items-center justify-center bg-black transition-opacity duration-300"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 9999,
          }}
          onClick={handleClose}
        >
          <div
            ref={containerRef}
            className="absolute inset-0 m-auto h-full w-full overflow-hidden lg:h-3/4 lg:max-h-[80vh] lg:w-3/4 lg:max-w-[80vw]"
            style={{
              cursor: scale > 1 ? (dragging ? "grabbing" : "grab") : "default",
              touchAction: "none",
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <img
              src={src}
              alt={alt}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "contain",
                transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                transition: dragging ? "none" : "transform 0.3s ease-out",
                zIndex: 1,
              }}
              draggable="false"
            />
            <div
              className="absolute bottom-4 left-1/2 -translate-x-1/2 transform rounded-full bg-c-blue-mono bg-opacity-50 px-4 py-2 font-bold text-white"
              style={{ zIndex: 2 }}
            >
              {scale > 1 ? (
                <span className="hidden lg:inline">
                  Scroll to zoom, drag to pan
                </span>
              ) : (
                <span className="hidden lg:inline">Scroll to zoom</span>
              )}
              <span className="lg:hidden">Pinch to zoom, drag to pan</span>
            </div>
            <button
              className="absolute right-4 top-4 transform rounded-full bg-c-blue-mono bg-opacity-50 p-2 text-white transition-transform duration-300 ease-in-out hover:scale-105"
              style={{ zIndex: 2 }}
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SmoothenlargeableImage;
