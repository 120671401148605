import React from "react";

const Modal = ({ isVisible, onClose, content }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative max-h-[90vh] w-4/5 overflow-y-auto overscroll-contain rounded-lg bg-gray-800 p-8 shadow-xl max-md:w-full max-md:p-6 md:w-1/2">
        <div className="prose prose-lg prose-invert max-w-none text-gray-300">
          {content}
        </div>
        <div className="mt-10 flex justify-end">
          <button
            className="rounded-md bg-c-blue-mono px-4 py-2 font-medium text-white shadow-md transition-colors duration-300 hover:bg-c-blue-mono/80 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
