import React from "react";

const StatusBadge = ({ projectStatus }) => {
  const getStatusColor = (projectStatus) => {
    switch (projectStatus) {
      case "Live":
        return "bg-green-600";
      case "In Progress":
        return "bg-yellow-600";
      case "On Hold":
        return "bg-gray-600";
      case "Sunsetted":
        return "bg-red-600";
      default:
        return "bg-blue-600";
    }
  };

  return (
    <span
      className={`rounded-full px-2 py-1 text-xs font-semibold text-white ${getStatusColor(projectStatus)}`}
    >
      {projectStatus}
    </span>
  );
};

const ProjectTile = ({
  image,
  title,
  description,
  liveLink,
  moreLink,
  github,
  projectStatus,
}) => {
  return (
    <div className="flex h-full flex-col overflow-hidden rounded-lg bg-gradient-to-br from-c-black to-gray-800 shadow-lg transition-all duration-300 hover:shadow-2xl">
      <div className="relative">
        <img className="h-48 w-full object-cover" src={image} alt={title} />
        <div className="absolute right-2 top-2">
          <StatusBadge projectStatus={projectStatus} />
        </div>
      </div>
      <div className="flex flex-grow flex-col p-6">
        <div className="mb-2 flex items-start justify-between">
          <h3 className="text-xl font-bold text-white">{title}</h3>
          {github && (
            <a
              href={github}
              target="_blank"
              rel="noreferrer"
              className="flex-shrink-0"
            >
              <button className="rounded bg-c-pink-mono p-2 text-white transition-colors duration-300 hover:bg-opacity-80">
                <img
                  className="h-6 w-6"
                  src="github-mark-white.svg"
                  alt="Github"
                />
              </button>
            </a>
          )}
        </div>
        <p className="mb-4 flex-grow text-gray-300">{description}</p>
        <div className="mt-auto flex flex-wrap gap-2">
          {liveLink ? (
            <a
              href={liveLink}
              target="_blank"
              rel="noreferrer"
              className="flex-grow"
            >
              <button className="w-full rounded bg-c-blue-mono px-4 py-2 font-bold text-white transition-colors duration-300 hover:bg-opacity-80">
                Live Site
              </button>
            </a>
          ) : (
            <button
              className="w-full flex-grow cursor-not-allowed rounded bg-gray-600 px-4 py-2 font-bold text-gray-300"
              disabled
            >
              Coming Soon
            </button>
          )}
          {moreLink ? (
            <a href={moreLink} className="flex-grow" rel="noreferrer">
              <button className="w-full rounded bg-c-purple-mono px-4 py-2 font-bold text-white transition-colors duration-300 hover:bg-opacity-80">
                Learn More
              </button>
            </a>
          ) : (
            <button
              className="w-full flex-grow cursor-not-allowed rounded bg-gray-600 px-4 py-2 font-bold text-gray-300"
              disabled
            >
              Write Up in Progress
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectTile;
