export const projects = [
  {
    title: "Online Dating for Gamers",
    liveLink: "https://edaters.gg",
    moreLink: null,
    image: "/edaters/edaters.png",
    github: null,
    description:
      "A dating app for gamers. Using fundamental Data Science and Machine Learning, we help users find their perfect match based on their gaming habits, preferences, and social desires.",
    projectStatus: "Live",
  },
  {
    title: "AI Video Game Coach",
    liveLink: "https://meru.gg/",
    moreLink: "/meru",
    image: "/meru/merugg.png",
    github: null,
    description:
      "Stats made simple. With a User-First design, we help simplify the overwhelming amount of stats by providing personable, actionable feedback, based on the User's data",
    projectStatus: "Live",
  },
  {
    title: "Bloom Chat - by BLOOM LOGIC Inc.",
    liveLink: "https://www.bloomchat.ai/",
    moreLink: "/bloom",
    image: "/bloom/bloom_0524.png",
    github: null,
    description:
      "Enterprise Data meets AI. Using Generative AI technologies, users can chat with their data to improve and optimize their daily work tasks.",
    projectStatus: "Live",
  },
  {
    title: "Finance Buddy",
    liveLink: null,
    moreLink: "/finance-buddy",
    image: "/financeai/RenewedLanding.png",
    github: null,
    description:
      "Helping users learn more about their financial story. Using fundamental Data Science and Generative AI, we help users visualize and converse with their financial data.",
    projectStatus: "In Development",
  },
  // {
  //   title: "Sports Recording System",
  //   pathway: "/sports-recording",
  //   description:
  //     "Escondido PAL, a non-profit organization, has provided generations of youth with a safe place to play sports. My brothers played basketball there, and when I moved away from home I wanted to make sure I can continue watching their games. My goal was to make a low cost video streaming device and service powered by AI, so that Escondido PAL can continue to unite families and communities through sports.",
  // },
  {
    title: "Wild AI",
    liveLink: null,
    // moreLink: "/wild-ai",
    image: "/wildai/game_recording_step1.png",
    github: "https://github.com/PepeTapia/WildAI",
    description:
      "WildAI is a bespoke tool created to help esports teams use fundamental data analysis in Wild Rift. Using Computer Vision and Machine Learning, we are able to extract data from the game and analyze player performance.",
    projectStatus: "Sunsetted",
  },
  // {
  //   title: "AWS AI/ML Scholarship",
  //   pathway: "/aws-ai-ml-scholarship",
  //   description:
  //     "This course covered the fundamentals of math within Machine Learning as well as learning prominent Machine learning coding packages. The result was creating an Image Classification system using transfer learning in two different ways: Command Line & Jupyter Notebook.",
  // },
  {
    title: "NotFoundGG",
    pathway: "/notfound-gg",
    liveLink: null,
    // moreLink: "/notfound-gg",
    image: "/notfoundgg/React_CSS_First_Draft.png",
    github: null,
    description:
      "Assisted in the develpoment of a full-stack web application for a Gaming asset creation company powered my Generative AI.",
    projectStatus: "Sunsetted",
  },
  // {
  //   title: "Valorant Esports analysis",
  //   pathway: "/loudgg-val",
  //   description:
  //     "Utilized fundamental Data Science and Machine Learning to create a tool that extracts crucial data from one game of Valorant.",
  // },
];
