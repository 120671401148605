import { React, useState } from "react";
import Modal from "../Modal";
const FinMore = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const tldrContent = (
    <div className="text text-c-black">
      <h2 className="mb-2 text-center text-2xl font-bold">
        More - FAQs and Important Notes.
      </h2>
      <p className="mb-4 text-center text-xs font-bold">
        Things I learned or things I think are important to highlight.
      </p>
      <p className="text-center text-lg font-bold">In Progress...</p>
    </div>
  );
  return (
    <div id="events" className="flex w-full flex-col">
      <div
        id="tooling"
        className="sticky -top-2 flex w-full gap-2 bg-c-black py-2"
      >
        <ul className="flex flex-wrap items-center gap-2 *:rounded-lg *:border *:border-c-blue *:bg-c-blue/50 *:px-2 *:py-0.5 *:text-center *:font-bold max-md:text-xs">
          <li>Vercel</li>
          <li>Docker</li>
          <li>Replit</li>
          <li>AWS</li>
          <li>Azure</li>
        </ul>
      </div>

      <div id="tldr" className="my-4 flex justify-center">
        <button
          onClick={handleOpenModal}
          className="w-1/3 rounded-lg bg-c-blue-mono p-2 font-bold text-c-black hover:bg-c-blue"
        >
          More in a nutshell
        </button>
      </div>

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        content={tldrContent}
      />

      <div id="eventcards" className="m-auto mt-8 flex flex-col gap-2">
        <div id="eventcard" className="flex justify-center">
          <div className="flex w-full flex-col">
            <h2 className="text-4xl font-bold">Write ups in progress..</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinMore;
