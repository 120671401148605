import { React, useEffect, useRef, useState } from "react";
//import logo from './logo.png';
import AudienceBar from "../components/AudienceBar";
import ProjectHeadline from "../components/Headline/ProjectHeadline";
import MeruBack from "../components/meru/meruback";
import MeruData from "../components/meru/merudata";
import MeruFront from "../components/meru/merufront";
import MeruMore from "../components/meru/merumore";
import "./index.css";
const sections = [
  {
    id: "situation",
    title: "Lowering the barrier of entry, Expanding the skill ceiling.",
    content:
      "Casual and competitive players share the same process of learning and improving, but the time needed to improve is getting longer! Meru is an application built with the purpose of helping players learn and improve quick through personalized coaching.",
  },
  {
    id: "task",
    title: "Creating a full stack application",
    content:
      "By leveraging AI/ML, I was able to streamline relevant actionable feedback to the user so they can spend more time gaming and less time learning.",
  },
  {
    id: "action",
    title: "Personalized iterations.",
    content:
      "We use the Riot Games API to ingest and process game data. Using my esports coaching experience, I would coach users, understand their pain points, and translate my solutions into a full stack application.",
  },
  {
    id: "result",
    title: "Results",
    content:
      "Every user is onboarded with a personal touch. I spend time with each user to understand them outside of gaming. Whether you're a 9-5 worker, pro esports player, or casual gamer, everyone deserves a personalized experience.",
  },
  {
    id: "role",
    title: "Applied AI Full Stack Developer",
    content:
      "As an Applied AI Full Stack Developer, I was responsible for the entire software development lifecycle. The challenges for this application helped me learn more about Frontend, Backend, and taking an application to production level witht low latency and high availability. I also learned a lot about managing tradeoffs regarding Architecture, technoogies, and prioritizing a user's experience.",
  },
];

const Meru = () => {
  const [currentDev, setCurrentDev] = useState("frontend");
  const overflowContainerRef = useRef(null);
  useEffect(() => {
    if (overflowContainerRef.current) {
      overflowContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentDev]);
  const imgComponent = (
    <img
      className="h-auto w-full rounded-lg shadow-xl"
      alt=""
      src="/meru/merugg.png"
    ></img>
  );
  return (
    <div className="mx-auto h-4/5 w-5/6 px-4 max-md:w-full">
      <ProjectHeadline
        mainText={"AI Coach for Video Games"}
        subText={
          "Helping players spend less time learning and more time gaming."
        }
        imgComponent={imgComponent}
        sections={sections}
      />
      <section className="m-auto mt-12 p-2 max-md:mt-4 max-md:p-0">
        <AudienceBar currentDev={currentDev} setCurrentDev={setCurrentDev} />
      </section>
      <section
        id="info"
        ref={overflowContainerRef}
        className="h-full overflow-y-auto overscroll-auto p-2"
      >
        {currentDev === "frontend" && <MeruFront />}
        {currentDev === "backend" && <MeruBack />}
        {currentDev === "datascience" && <MeruData />}
        {currentDev === "more" && <MeruMore />}
      </section>
    </div>
  );
};

export default Meru;
