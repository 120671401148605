import React from "react";

const Tooling = ({ tools }) => {
  return (
    <div
      id="tooling"
      className="w-full rounded-lg bg-gray-800 bg-opacity-30 py-6 shadow-lg"
    >
      <div className=" mx-auto px-4">
        <h3 className="mb-4 text-xl font-bold text-white">Tools used:</h3>
        <ul className="flex flex-wrap gap-3">
          {tools.map((tool, index) => (
            <li key={index}>
              <a
                href={tool.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center rounded-md bg-gradient-to-r from-blue-500 to-blue-800 px-3 
                           py-2 text-sm font-medium text-white
                           transition-all duration-300 ease-in-out
                           hover:from-blue-600 hover:to-blue-800 hover:shadow-lg
                           focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span>{tool.name}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1.5 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Tooling;
