import React from "react";
import Headline from "../components/Headline";
import PreviewPanel from "../components/ProjectPreview";
//import logo from './logo.png';
import { projects } from "../components/ProjectPreview/projects";

const Home = () => {
  return (
    <div className="m-auto h-screen w-5/6">
      <Headline />
      <PreviewPanel projectList={projects} />
    </div>
  );
};

export default Home;
