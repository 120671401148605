import React from "react";
import SmoothenlargeableImage from "../EnlargedImage";

/* ######################### All content that is related to Bloom Frontend ######################### */

export const BloomFrontTools = [
  { name: "AWS Amplify", link: "https://aws.amazon.com/amplify/" },
  { name: "React", link: "https://reactjs.org/" },
  { name: "CSS", link: "https://developer.mozilla.org/en-US/docs/Web/CSS" },
  {
    name: "Javascript",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  },
  { name: "npm", link: "https://www.npmjs.com/" },
  { name: "Node.js", link: "https://nodejs.org/" },
  { name: "Tailwind CSS", link: "https://tailwindcss.com/" },
];

export const BloomFrontTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-2 text-center text-2xl font-bold max-lg:text-xl">
      Frontend - FAQs and Important Notes.
    </h2>
    <p className="mb-4 text-center text-xs font-bold">
      Things I learned or things I think are important to highlight.
    </p>
    <ul className="mb-4 list-outside list-disc px-4">
      <li>
        Hosted on Vercel then switched to AWS Amplify for better CI/CD and
        preparation for production.
      </li>
      <li>
        Start with the user and iterate quickly. Working with stakeholders and
        users is key for iterations, but learning from friends or coworkers is
        key for improving on your knowledge to make better iterations.
      </li>
      <li>
        Read the documentation, learn how to debug rigorously, and keep track of
        the DOM at all times.
      </li>
    </ul>
    <h3 className="mb-2 text-center text-2xl font-bold">FAQs</h3>
    <p className="text-center text-lg font-bold"></p>
    <p>
      I wanted to learn the fundamentals of React before diving into Next.js.
      I'm confident in my ability to pick up new tools/frameworks, and Next.js
      is on my todo list!
    </p>
    <p className="text-center text-lg font-bold">
      Why didn't you use UI Component libraries?
    </p>
    <p>
      No particular reason against it, but I wanted to learn the fundamentals.
      Once I started adding graphs and charts, I knew I could save time if I
      made the switch so I read the documentation and began implementing the
      library.
    </p>
  </div>
);

const BloomFrontOverallText1 = (
  <>
    <p className="mt-10">
      I <b>am</b> a problem solver. I took the product concepts and learned bits
      of Color Theory, UI/UX, and other design principles to create usable
      interfaces, gathered user feedback, and iterated from there.
    </p>
    <p className="mt-2">
      As long as the functionality was there, it was more important to iterate,
      fail, and learn than to just not do anything at all.
    </p>
  </>
);

const BloomFrontOverallImages1 = (
  <SmoothenlargeableImage
    src="/bloom/orangecrew_v1.png"
    title="Orange Crew Version 1"
    alt="Orange Crew Version 1"
  />
);

export const BloomFrontOverall1 = {
  display: BloomFrontOverallText1,
  images: BloomFrontOverallImages1,
};

const BloomFrontOverall2Text = (
  <>
    <p className="mt-10">
      I did not use any UI component libraries for this project. I did
      everything in React, and when interactions were needed, I used JavaScript
      and CSS. Later, I transitioned to using Tailwind CSS for faster
      development. I used Axios to make API calls to connect to my FastAPI
      backend.
    </p>
    <div className="mt-4 flex flex-col gap-2">
      <p>
        When learning, I find the documentation and examples because I promote
        self-sufficiency. If I get stuck on something, I find experts or peers
        to learn from.
      </p>
      <p>
        Here are my favorite places to refer to when I was working on this
        project.
      </p>
      <div className="flex gap-2">
        <a
          href="https://react.dev/learn/you-might-not-need-an-effect"
          target="_blank"
          rel="noreferrer"
          className="p-2 font-bold text-c-blue-mono "
        >
          You might not need an effect
        </a>
        <a
          href="https://react.dev/learn/describing-the-ui"
          target="_blank"
          rel="noreferrer"
          className="p-2 font-bold text-c-blue-mono "
        >
          Describing the UI
        </a>
      </div>
    </div>
  </>
);

const BloomFrontOverallImages2 = (
  <SmoothenlargeableImage
    src="/bloom/OrangeCrewNLP.png"
    title="Orange Crew Chat Interface"
    alt="Orange Crew Chat Interface"
  />
);

export const BloomFrontOverall2 = {
  display: BloomFrontOverall2Text,
  images: BloomFrontOverallImages2,
};

const BloomFrontOverallText3 = (
  <>
    <p className="mt-10">
      I initially tried to impress rather than being practical. I focused on
      getting the core functionality working first and then iterated on the
      design later. Just because I am learning something, it doesn't mean I
      can't enforce high standards. While implementing the design, I constantly
      referred to best practices for React fundamentals.
    </p>
  </>
);

const BloomFrontOverallImages3 = (
  <SmoothenlargeableImage
    src="/bloom/orangecrew_v5_1.png"
    title="Orange Crew Landing Page Version 5"
    alt="Orange Crew Landing Page Version 5"
  />
);

export const BloomFrontOverall3 = {
  display: BloomFrontOverallText3,
  images: BloomFrontOverallImages3,
};

const BloomFrontOverallText4 = (
  <>
    <p className="mt-10">
      When the product reached a stage that required a more polished look, the
      client hired a UI/UX designer to take over the design. I worked closely
      with the designer to implement the new design and ensured that the
      functionality remained intact. For data privacy reasons, here is the
      mock-up design that was implemented, with only a few compromises made in
      the final implementation.
      <br />
    </p>
  </>
);

const BloomFrontOverallImages4 = (
  <SmoothenlargeableImage
    src="/bloom/bloom_0524.png"
    title="Bloom Chat rebrand dashboard."
    alt="Bloom Chat rebrand dashboard."
  />
);

export const BloomFrontOverall4 = {
  display: BloomFrontOverallText4,
  images: BloomFrontOverallImages4,
};

/* ######################### All content that is related to Bloom Backend ######################### */

export const BloomBackTools = [
  { name: "FastAPI", link: "https://fastapi.tiangolo.com/" },
  { name: "Bcrypt", link: "https://pypi.org/project/bcrypt/" },
  { name: "Neo4j Graph Database", link: "https://neo4j.com/" },
  { name: "NoSQL Database", link: "https://www.mongodb.com/nosql-explained" },
  { name: "MongoDB", link: "https://www.mongodb.com/" },
  { name: "Python", link: "https://www.python.org/" },
  {
    name: "Cypher",
    link: "https://neo4j.com/developer/cypher-query-language/",
  },
  { name: "Pydantic", link: "https://pydantic-docs.helpmanual.io/" },
  { name: "OpenAI API", link: "https://www.openai.com/" },
  { name: "Qdrant", link: "https://qdrant.ai/" },
  { name: "Amazon Web Services", link: "https://aws.amazon.com/" },
  { name: "Replit", link: "https://replit.com/" },
];

export const BloomBackTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-2 text-center text-2xl font-bold">
      Backend - FAQs and Important Notes.
    </h2>
    <p className="mb-4 text-center text-xs font-bold">
      Things I learned or things I think are important to highlight.
    </p>
    <ul className="mb-4 list-outside list-disc px-4">
      <li>
        Langchain and Llamaindex are great tools, but a majority of problems can
        be solved using raw API calls. Granular control rewards the user and
        developer!
      </li>
      <li>
        Designing and developing API architecture with multiple third party
        integrations was a great challenge!
      </li>
      <li>
        Currently hosted on AWS using Fargate, VPC, ECS, EC2, CloudWatch, and
        S3.
      </li>
    </ul>
    <h3 className="mb-2 text-center text-2xl font-bold">FAQs</h3>
    <p className="text-center text-lg font-bold">
      Why did you pick your tools?
    </p>
    <p>
      Tools will change and learning them is a part of the process. The tools I
      chose, FastApi, Neo4j, Replit, etc.. were for speed of development and
      familarity. Later we migrated to MongoDB, AWS, and Qdrant and I learned
      those tools as well.
    </p>
    <p className="text-center text-lg font-bold">
      Data Ingestion and Processing - How does it work?
    </p>
    <p>
      Initial ingestion is on the first 90 days of user data and processing done
      is on ingestion, but raw data and processed data are stored separately.
      This allows us to then change to a webhook pattern after initial
      ingestion. Changes to the Data are captured and processed in real time,
      stored in our "datalake" and processed into our production database. The
      benefits from this allowed the user to query the available data as its
      ingested.
    </p>
  </div>
);

const BloomBackOverallText1 = (
  <>
    <p className="mt-10">
      Having full ownership of the data pipelines, it was important to implement
      a long term solution that could handle the data ingestion and processing
      for an AI Chat application. Utilizing FastAPI, Pydantic, Replit, I was
      able to dive deep on each integration and build a robust data ingestion
      pipeline that could handle multiple data sources and user requests.
      <br />
    </p>
  </>
);

const BloomBackOverallImages1 = (
  <SmoothenlargeableImage
    src="/bloom/bloomchatapi.png"
    title="FastAPI Server"
    alt="FastAPI Server"
  />
);

export const BloomBackOverall1 = {
  display: BloomBackOverallText1,
  images: BloomBackOverallImages1,
};

const BloomBackOverallText2 = (
  <>
    <p className="mt-10">
      Handling multiple ingestion sources, user authorization, API requests, and
      data processing all in one service can be intensive! Using asynchronous
      code and the power of FastAPI, I was able to handle simultaneously
      ingesting 10,000 emails, 5000 tickets, user sign ups, and calling our AI
      Engine chat endpoint.
      <br />
      <br />
      The plans are to later break this down into microservices to make it
      easier to maintain and scale.
    </p>
  </>
);

const BloomBackOverallImages2 = (
  <SmoothenlargeableImage
    src="/bloom/fastapi_arch.png"
    title="FastAPI Architecture for Bloom data ingestion."
    alt="diagram of fast a-p-i architecture for Bloom data ingestion."
  />
);

export const BloomBackOverall2 = {
  display: BloomBackOverallText2,
  images: BloomBackOverallImages2,
};

const BloomBackOverallText3 = (
  <>
    <p className="mt-4">
      Modern GenAI based chat applications utilize R.A.G architecture with
      frameworks like <code className="">Langchain</code> and{" "}
      <code className="">Llamaindex</code>, while using OpenAI's API to generate
      responses.
    </p>
    <p className="">
      I started with Langchain but at the time function calling did not exist,
      so I switched to direct API calls.
    </p>
    <p className="my-2 self-center rounded-lg bg-c-pink p-2 text-center text-c-black">
      <strong>R</strong>etrieving <strong>I</strong>nformation{" "}
      <strong>S</strong>ub <strong>A</strong>utonomously{" "}
      <strong>(R.I.S.A)</strong> architecture.
    </p>
    <p className="text-center text-sm italic">
      Yes, another name for it - but this time we are describing the way in
      which we get our data.
    </p>
    <p className="mt-4">
      Using <strong>R.I.S.A</strong>, we were able to retrieve information from
      different domains when needed - as opposed to searching through a full
      embedding space each time. Using this method allowed for the AI Engine to
      fetch data from multiple vector spaces and data sources
      asynchronously,reducing requests from 30 seconds to 4 seconds under
      gpt-3.5 and improving tool selection to 85% accuracy.
    </p>
  </>
);

const BloomBackOverallImages3 = (
  <SmoothenlargeableImage
    src="/bloom/risa_architecture.png"
    title="RISA AI Engine Architecture"
    alt="RISA AI Engine Architecture"
  />
);

export const BloomBackOverall3 = {
  display: BloomBackOverallText3,
  images: BloomBackOverallImages3,
};

const BloomBackOverallText4 = (
  <>
    <p className="mt-10">
      User authorization is key to ensuring that the right data is being
      accessed by the right people. By using JWT tokens and user id constraints,
      we were able to ensure that the data being accessed was only the data that
      the user was allowed to see.
    </p>
  </>
);

const BloomBackOverallImages4 = (
  <SmoothenlargeableImage
    src="/bloom/Bloom_Results3.png"
    title="Bloom Chat query example."
    alt="Bloom Chat query example."
  />
);

export const BloomBackOverall4 = {
  display: BloomBackOverallText4,
  images: BloomBackOverallImages4,
};

/* ######################### All content that is related to Bloom AI/ML & Data Science ######################### */

export const BloomAIMLTools = [
  { name: "PyTorch", link: "https://pytorch.org/" },
  { name: "TensorFlow", link: "https://www.tensorflow.org/" },
  { name: "Scikit-learn", link: "https://scikit-learn.org/stable/" },
  { name: "Pandas", link: "https://pandas.pydata.org/" },
  { name: "Jupyter Notebook", link: "https://jupyter.org/" },
  { name: "OpenAI", link: "https://www.openai.com/" },
  { name: "Qdrant", link: "https://qdrant.ai/" },
  { name: "Neo4j", link: "https://neo4j.com/" },
  { name: "Python", link: "https://www.python.org/" },
  { name: "AWS", link: "https://aws.amazon.com/" },
];

export const BloomAIMLTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-2 px-4 text-center text-xl font-bold">
      AI / Data Science - FAQs and Important Notes.
    </h2>
    <p className="mb-4 text-center text-xs font-bold">
      Things I learned or things I think are important to highlight.
    </p>
    <ul className="mb-4 list-outside list-disc p-4">
      <li>
        Innovation and simplication are key. I chose to use existing LLM models
        to speed up the process, but I took creative liberties to create a
        custom architecture.
      </li>
      <li>
        Remain aware of new technologies and new possibilities. In a rapidly
        changing field, it's important to stay up to date.
      </li>
    </ul>
    <h3 className="mb-2 text-center text-2xl font-bold">FAQs</h3>
    <p className="text-center text-lg font-bold">What was your process?</p>
    <p>
      Coming from an academic background, I read research papers and kept up to
      date with the latest technologies and models available. Having a strong
      understanding of Natural Language Process and Machine Learning helped me
      focus more on building a strong system architecture, as opposed to
      spending time on prompt tuning and model training.
    </p>
    <p className="text-center text-lg font-bold">
      What is your experience in AI/ML?
    </p>
    <p>
      My main focus in University was AI/ML, having spent summers with
      Professors working on Machine Learning based research projects. My courses
      included Differential Equations, Linear Algebra, Discrete Math, and
      Probability Theory, which inspired me to dive deep into the low level math
      behind AI/ML. I have also completed additional programs, such as{" "}
      <a
        href="https://aws.amazon.com/machine-learning/scholarship/"
        target="_blank"
        rel="noreferrer"
        className="font-bold "
      >
        AWS Machine Learning Scholarship
      </a>
      .
    </p>
  </div>
);

const BloomAIMLOverallText1 = (
  <>
    <p className="mt-10">
      Instead of jumping straight into the OpenAI API, I revisited the
      fundamentals of Natural Language Processing, transformers, and tokenizers
      using HuggingFace's tutorials. By fine-tuning pretrained models with
      TensorFlow and PyTorch on my own data, I strengthened my grasp of the
      underlying concepts.
      <br />
      With the rising popularity of Vector Databases, I focused on the basics:
      storing vectors in a database and performing similarity searches. This may
      be an oversimplification, but it worked for me. I compared and evaluated
      Vector Databases such as Pinecone, Chroma, and Milvus, ultimately choosing
      Neo4j for my use case.
    </p>
    <div className="mt-4 flex flex-col gap-2">
      <p>Here are a few of my favorite learning resources:</p>
      <div className="flex gap-2 ">
        <a
          href="https://huggingface.co/learn/nlp-course/chapter1/1"
          target="_blank"
          rel="noreferrer"
          className="p-2 font-bold text-c-blue-mono   hover:text-c-blue"
        >
          HuggingFace NLP Course
        </a>
        <a
          href="https://github.com/openai/openai-cookbook/tree/main"
          target="_blank"
          rel="noreferrer"
          className="p-2 font-bold text-c-blue-mono   hover:text-c-blue"
        >
          OpenAI Cookbook
        </a>
      </div>
    </div>
  </>
);

const BloomAIMLOverallImages1 = (
  <SmoothenlargeableImage
    src="/bloom/research_review.png"
    title="Research Process Diagram"
    alt="Research Process Diagram"
  />
);

export const BloomAIMLOverall1 = {
  display: BloomAIMLOverallText1,
  images: BloomAIMLOverallImages1,
};

const BloomAIMLOverallText2 = (
  <>
    <p className="mt-10">
      We started with <code>Microsoft</code>, <code>Autotask</code>,{" "}
      <code>QuickBooks</code>, and Phone call recording data converted into{" "}
      <code>transcripts</code>, from <code>3CX</code>. OpenAI's embedding model
      limits us to 8191 tokens per API request. After analyzing the data, I
      concluded:
      <ul className="list-inside list-disc">
        <li>
          35% of the data surpassed the token limit, mainly Emails and
          Documents.
        </li>
        <li>
          82% of Emails contained noise such as signatures, disclaimers, and
          headers.
        </li>
        <li>
          Documents and Emails contained Unicode characters that added to the
          token count.
        </li>
      </ul>
      <br />
      <p>After cleaning the data, I reduced the token count:</p>
      <ul>
        <li>Emails: 71.4% reduction in tokens after filtering and cleaning.</li>
        <li>Documents: 32.2% reduction in tokens after cleaning.</li>
      </ul>
      <p>
        Initial Tokens: <strong>21,244,686</strong>
      </p>
      <p>
        Tokens after cleaning: <strong>10,794,217</strong>
      </p>
      <p>
        We achieved almost <strong>50%</strong> token savings, which can be
        significant in embeddings. Combined with my <strong>R.I.S.A</strong>{" "}
        architecture, we saved{" "}
        <strong>50% in cost on conversational tokens.</strong>
      </p>
      <br />
    </p>
  </>
);

const BloomAIMLOverallImages2 = (
  <SmoothenlargeableImage
    src="/bloom/data_cleaning.png"
    title="Data Cleaning process and results"
    alt="Data Cleaning process and results"
  />
);

export const BloomAIMLOverall2 = {
  display: BloomAIMLOverallText2,
  images: BloomAIMLOverallImages2,
};

const BloomAIMLOverallText3 = (
  <>
    <p className="mt-4">
      After experimenting with training NLP models, I realized it was
      unnecessary for the use cases of generating business text. The data is
      business-oriented, and the domain knowledge needed is perfectly suited for
      most large models from OpenAI or Anthropic.
      <br />
      Although, training a model did help with how the model responded to
      certain queries. By training a model on the types of questions a user
      would ask and structuring the response in a way the optimizes our tool
      selection, I was able to improve tool selection to a 97% accuracy rate.
      <br />
      The decision between open-source models and enterprise models was based on
      availability, cost, and time sensitivity. The project's goal was not to
      create a new revolutionary model but to{" "}
      <span className="bg-clip-text font-bold">
        optimize a business and save the CEO's time.
      </span>
    </p>
  </>
);

const BloomAIMLOverallImages3 = (
  <SmoothenlargeableImage
    src="/bloom/risa_architecture.png"
    title="RISA AI Engine Architecture"
    alt="RISA AI Engine Architecture"
  />
);

export const BloomAIMLOverall3 = {
  display: BloomAIMLOverallText3,
  images: BloomAIMLOverallImages3,
};

/* ######################### All content that is related to Bloom More ######################### */

export const BloomMoreTools = [
  { name: "Vercel", link: "https://vercel.com/" },
  { name: "Docker", link: "https://www.docker.com/" },
  { name: "Replit", link: "https://replit.com/" },
  { name: "AWS", link: "https://aws.amazon.com/" },
  { name: "Azure", link: "https://azure.microsoft.com/en-us/" },
  { name: "Google Cloud", link: "https://cloud.google.com/" },
  { name: "Fargate", link: "https://aws.amazon.com/fargate/" },
];

export const BloomMoreTLDR = (
  <div className="text-gray-300">
    <h2 className="mb-2 text-center text-2xl font-bold">
      More - FAQs and Important Notes.
    </h2>
    <p className="mb-4 text-center text-xs font-bold">
      Things I learned or things I think are important to highlight.
    </p>
    <ul className="mb-4 list-outside list-disc">
      <li>
        I rolled my own User Auth flow, created custom OpenAI api tools, and
        implemented Langchain frameworks. At the end of the day, I will choose
        the best balance of time, cost, security, and team cohesion to get the
        job done.
      </li>
      <li>
        Strong business sense goes a long way and it helps being able to talk to
        stakeholders in non-technical terms. Keep it simple!
      </li>
      <li>
        When working with third party tools, it is a good idea to talk to peers
        or other community members to share findings and learn the tooling
        together!
      </li>
    </ul>
    <h3 className="mb-2 text-center text-2xl font-bold">FAQs</h3>
    <p className="text-lg font-bold">What were your biggest takeaways?</p>
    <p>
      I onboarded senior developers, coordinated with stakeholders, and managed
      the product timeline. It's not my first time being in a leadership
      position, but it was my first time in tech! It was fun to apply myself in
      a technical space!
    </p>
    <p className="text-lg font-bold">
      What were your biggest technical challenges?
    </p>
    <p>
      Learning the nuances of each technical discipline was a challenge. From
      frontend concepts like SSR, bundling, and Auth handling to backend
      concepts like API development, database query optimizations, indexing, and
      session/transaction management. Quickly scaling myself to a higher level
      of problem solving, pattern recognition, and debugging was a great
      challenge.
    </p>
  </div>
);

const BloomMoreOverallText1 = (
  <>
    <p className="mt-8">
      Some data sources were not available through APIs, namely 3CX - a VOIP
      system, so my teammate,{" "}
      <a
        href="https://www.linkedin.com/in/paramteraiya/?originalSubdomain=in"
        target="_blank"
        rel="noreferrer"
        className="font-bold text-c-blue-mono  hover:text-c-blue"
      >
        Param Teraiya
      </a>{" "}
      created a web scraper that automated the process of logging in, navigating
      to the call recordings, and downloading the audio files.
      <br />
    </p>
    <p className="mt-2">
      I already had a Jupyter Notebook that converted audio files into
      transcripts using{" "}
      <a
        href="https://platform.openai.com/docs/guides/speech-to-text"
        referrerPolicy="noreferrer"
        target="_blank"
        rel="noreferrer"
        className="font-bold text-c-blue-mono  hover:text-c-blue"
      >
        OpenAI's Whisper API
      </a>{" "}
      but it needed to be automated and improved. After transforming the Jupyter
      Notebook into production code, hosting it on{" "}
      <a
        href="https://replit.com/~"
        referrerPolicy="noreferrer"
        target="_blank"
        rel="noreferrer"
        className="font-bold text-c-blue-mono  hover:text-c-blue "
      >
        Replit
      </a>{" "}
      for easy access, we were able to automate the process of converting audio
      files to text.
      <br />
    </p>
    <p className="mt-2">
      <span className="bg-red-500 bg-clip-text font-bold text-transparent">
        But OpenAI did not have asynchronous support for Whisper!
      </span>
      <br />
      To fix that, we created our own asynchronous wrapper, shown in the
      attached image.
    </p>

    <p className="mt-2">
      The final result was a tool that:
      <ul className="mb-4 list-outside list-disc px-4">
        <li>Automated the process of downloading audio files.</li>
        <li>
          Reduce processing time of 1000 phone calls from 30 minutes to 2
          minutes.
        </li>
        <li>
          API endpoint connected to our website so users can input their 3CX
          credentials and begin downloading their phone calls
        </li>
      </ul>
    </p>
  </>
);

const BloomMoreOverallImages1 = (
  <SmoothenlargeableImage
    src="/bloom/codeimage-snippet_27.svg"
    title="OpenAI Whisper Wrapper"
    alt="OpenAI Whisper Wrapper"
  />
);

export const BloomMoreOverall1 = {
  display: BloomMoreOverallText1,
  images: BloomMoreOverallImages1,
};

const BloomMoreOverallText2 = (
  <>
    <p className="mt-10">
      QuickBooks OAuth 2.0 implementation was handled by our application while
      Microsoft has{" "}
      <a
        href="https://learn.microsoft.com/en-us/entra/identity-platform/msal-overview"
        target="_blank"
        rel="noreferrer"
      >
        MSAL
      </a>{" "}
      for their authentication. Each service was isolated and had its own API
      ingestion pipeline. This allowed for easy debugging and maintenance.
    </p>
    <p className="mt-2">
      The API ingestion pipeline was created to handle the following:
      <ul className="mb-4 list-outside list-disc px-4">
        <li>SSO and OAuth 2.0 implementation</li>
        <li>Handling credentials</li>
        <li>Reusing code for future API ingestion</li>
      </ul>
    </p>
    <p>
      The database modeling changed over time as we moved from Neo4j to MongoDB,
      but the core of it was the same.
      <ul className="mb-4 list-outside list-disc px-4">
        <li>
          Keep track of the third party integrations that we have using an{" "}
          <code>Application</code> entity.
        </li>
        <li>
          Create a <code>UserApplication</code> entity and relate it to the{" "}
          <code>User</code> and the <code>Application</code>.
        </li>
        <li>Store the credentials and prevent exposure to the client.</li>
      </ul>
    </p>
  </>
);

const BloomMoreOverallImages2 = (
  <SmoothenlargeableImage
    src="/bloom/fastapi_arch.png"
    title="FastAPI Architecture"
    alt="FastAPI Architecture"
  />
);

export const BloomMoreOverall2 = {
  display: BloomMoreOverallText2,
  images: BloomMoreOverallImages2,
};
